var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dragablemodal',{ref:"dragablemodal",attrs:{"name":'postingsplitmodal',"height":'auto',"width":900}},[_c('header',[_vm._v(" "+_vm._s(_vm.T( "Web.Generic.Invoices.Reconciliations.PostingSplitPopup.Title", "Specify distribution" ))+" ")]),_c('div',{staticClass:"modals-content"},[(_vm.line)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"line-preview"},[_c('span',[_vm._v(_vm._s(_vm.format_date(_vm.line.date)))]),_c('span',[_vm._v(_vm._s(_vm.line.text)),_c('br'),_c('small',[_vm._v(_vm._s(_vm.line.description))])]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.line.amount)))]),_c('span',[_vm._v(_vm._s(_vm.line.unit))]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.line.priceAmount)))]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.line.priceTotal)))])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"postings-list"},[_c('ul',_vm._l((_vm.localPostings),function(posting,index){return _c('li',{key:index},[_c('span',[_vm._v(" "+_vm._s(_vm.getAccountFromId(posting.postingDestinationInfoCardId) .name)+" ("+_vm._s(_vm.getAccountFromId(posting.postingDestinationInfoCardId) .refId)+") ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineAmount'),"label-for":"text-quantity"}},[_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"append":_vm.unitTypeOptions[posting.postingUnit]}},[_c('b-form-input',{attrs:{"type":"number","id":"text-quantity","trim":"","value":posting.postingQuantity},on:{"change":function (value) { return _vm.postingAmountChanged(
                                  posting.postingDestinationInfoCardId,
                                  value
                                ); }}})],1)],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnit'),"label-for":"unit-input"}},[_c('b-form-select',{attrs:{"id":"unit-input","options":_vm.unitTypeOptions,"disabled":""},model:{value:(posting.postingUnit),callback:function ($$v) {_vm.$set(posting, "postingUnit", $$v)},expression:"posting.postingUnit"}}),(
                            !_vm.valueIsNotEmptyOrUnknown(posting.postingUnit)
                          )?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.UnitMissingText", "Provide unit")))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnitPrice'),"label-for":"total-amount"}},[_c('b-form-input',{attrs:{"id":"text-amount","trim":"","value":_vm._f("toCurrency")(_vm.line.priceAmount),"disabled":true}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnitTotal'),"label-for":"total-amount"}},[_c('b-form-input',{attrs:{"id":"text-amount","trim":"","value":_vm._f("toCurrency")(((_vm.line.priceTotal / _vm.line.amount) *
                              posting.postingQuantity)),"disabled":true}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineAmount'),"label-for":"text-quantity"}},[_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"append":_vm.unitTypeOptions[posting.postingUnit]}},[_c('b-form-input',{attrs:{"type":"number","id":"text-quantity","trim":"","value":posting.postingSecondaryQuantity}})],1)],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.Generic.Invoices.LineUnit'),"label-for":"unit-input"}},[_c('b-form-select',{attrs:{"id":"unit-input","options":_vm.unitTypeOptions},model:{value:(posting.postingSecondaryUnit),callback:function ($$v) {_vm.$set(posting, "postingSecondaryUnit", $$v)},expression:"posting.postingSecondaryUnit"}}),(
                            !_vm.valueIsNotEmptyOrUnknown(
                              posting.postingSecondaryUnit
                            )
                          )?_c('small',{staticClass:"missing-text"},[_vm._v(_vm._s(_vm.T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.UnitMissingText", "Provide unit")))]):_vm._e()],1)],1)],1)],1)}),0),(!_vm.splitIsValid)?_c('h4',{staticClass:"text-danger float-left"},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.Error", "The distribution sum has to be equal to total sum"))+" ")]):_vm._e()])])],1)],1):_vm._e()],1),_c('footer',{staticClass:"modal-footer"},[_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","disabled":!_vm.splitIsValid},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Approve", "Approve"))+" ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"light"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Cancel"))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }