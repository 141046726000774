import BaseService from "../base/base.service";

export default class ReportsService extends BaseService {
    constructor() {
        super('concern/statistics')
    }

    async getWasteAmountsReportForLocation(locationId, fromDate, toDate) {
        return await super.get("waste-amounts/fractions", {
            locationId: locationId,
            fromDate: fromDate,
            toDate: toDate
        });
    }

    async getPostingsReportForLocation(fromDate, toDate, locationId) {
        return await super.get("postings", {
            fromDate: fromDate,
            toDate: toDate,
            locationId: locationId
        });
    }

    async getPostingEntries(fromDate, toDate, locationId) {
        return await super.get("postings/entries", {
            fromDate: fromDate,
            toDate: toDate,
            locationId: locationId
        });
    }
}