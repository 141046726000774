<template>
  <div class="flex-table">
    <div v-if="showHeader" class="header">
      <slot name="header"> </slot>
    </div>
    <div class="body">
      <slot name="body"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  &.dark-layout {
    .flex-table {
      background-color: #1b2234;
      color: #d0d2d6;
      .header {
        background-color: #343d55;
      }

      span {
        border-top: 1px solid gray;
      }

      .flex-row {
        &:nth-of-type(odd) {
          background-color: #283046;
        }
        &.posted {
          background-color: #30cf84;
        }
      }
    }
  }
}
.flex-table {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #6e6b7b;
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #f3f2f7;
  }

  span {
    padding: 0.72rem 2rem;
    border-top: 1px solid #ebe9f1;
    letter-spacing: 0.5px;
    display: block;
    width: 50%;
    text-align: left;

    &.action {
      position: absolute;
      right: 5px;
      top: 5px;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      border: none;
    }
  }

  .flex-row {
    position: relative;
    span {
      width: 20%;
    }
    &.group {
      span {
        width: 50%;
      }
    }
    &.drag {
      cursor: grab;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    display: flex;
    justify-content: space-between;
    &:nth-of-type(odd) {
      background-color: #fafafc;
    }
    &.posted {
      background-color: #30cf84;
      font-weight: bold;
    }
  }
}
</style>