<template>
  <div>
    <div
      class="posting-cat-item d-flex align-items-center"
      @click="expanded = !expanded"
      :class="{ active: expanded }"
    >
      <div>{{ T(`Web.Generic.PostingCategories.${category}`) }}</div>
      <div>
        <label>{{T('Web.Generic.UnitOfQuantity', 'Unit of quantity')}}</label>
        <span>{{ totalQty | toCurrency }}</span>
      </div>
      <div>
        <label><!--{{T('Web.Generic.PricePrUnit', 'Price pr. unit')}}--></label>
        <span><!--{{ totalUnitPrice | toCurrency }}--></span>
      </div>

      <div>
        <label>{{T('Web.Generic.TotalPrice', 'Total price')}}</label>
        <span>{{ totalPrice | toCurrency }}</span>
      </div>
      <div>
        <feather-icon
          :icon="expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="25"
        />
      </div>
    </div>
    <div class="expand-content" v-if="expanded">
      <posting-month-entry-item
        v-for="(month, index) in months"
        :key="index"
        :month="month"
        :entries="getEntriesForMonth(month)"
        :category="category"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PostingMonthEntryItem from "./PostingMonthEntryItem.vue";

export default {
  components: { PostingMonthEntryItem },
  props: {
    category: {
      required: true,
    },
    entries: {
      required: true,
    },
    fromDate: {
      required: true,
    },
    toDate: {
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    months() {
      let list = [];
      var fromDate = moment(this.fromDate);
      var toDate = moment(this.toDate);
      var currDate = fromDate;

      while (currDate <= toDate) {
        list.push({
          label: currDate
            .toDate()
            .toLocaleString("en-us", { month: "short", year: "numeric" }),
          year: currDate.toDate().getFullYear(),
          month: currDate.toDate().getMonth() + 1,
        });
        currDate = currDate.add(1, "M");
      }
      return list;
    },
    totalUnitPrice() {
      return this.entries.reduce((curr, prev) => (curr += prev.unitPrice), 0);
    },
    totalPrice() {
      return this.entries.reduce((curr, prev) => (curr += prev.totalPrice), 0);
    },

    totalWasteCollections() {
      return this.entries.reduce((curr, prev) => (curr += prev.quantity), 0);
    },
    totalEconnomy() {
      return this.entries.reduce((curr, prev) => (curr += prev.economic), 0);
    },
    totalTonsCollected() {
      return this.entries.reduce(
        (curr, prev) => (curr += prev.tonsWasteCollected),
        0
      );
    },
    totalQty() {
      return this.entries.reduce((curr, prev) => {
        console.log(curr);
        return curr += prev.quantity;
      }, 0);
    },
  },
  methods: {
    getEntriesForMonth(month) {
      return this.entries.filter((e) => {
        var entryDate = moment(e.entryDate).toDate();
        if (
          entryDate.getFullYear() == month.year &&
          entryDate.getMonth() + 1 == month.month
        )
          return e;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.posting-cat-item {
  height: 60px;
  background: #f4f4fb;
  border-radius: 4px;
  margin: 5px 0px;
  padding: 0px 15px;

  &:hover,
  &.active {
    background: rgba(64, 126, 61, 0.12);
    cursor: pointer;
  }

  div {
    &:first-child {
      font-weight: bold;
      font-size: 20px;
    }
    &:last-child {
      text-align: right;
      width: 4%;
    }
    &:not(:last-child) {
      width: 24%;
    }
    display: flex;
    flex-direction: column;
    label {
      font-weight: bold;
      text-transform: uppercase;
    }
    &:not(:first-child) {
      align-items: center;
    }
  }
}
.expand-content {
  margin-left: 15px;
}
</style>