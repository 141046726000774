<template>
  <div class="wrap" :class="{ disabled: disabled }">
    <span
      :class="{ active: isActive('Monday') }"
      @click="toggleDaySelected('Monday')"
      >M</span
    >
    <span
      :class="{ active: isActive('Tuesday') }"
      @click="toggleDaySelected('Tuesday')"
      >T</span
    >
    <span
      :class="{ active: isActive('Wednesday') }"
      @click="toggleDaySelected('Wednesday')"
      >O</span
    >
    <span
      :class="{ active: isActive('Thursday') }"
      @click="toggleDaySelected('Thursday')"
      >T</span
    >
    <span
      :class="{ active: isActive('Friday') }"
      @click="toggleDaySelected('Friday')"
      >F</span
    >
    <span
      :class="{ active: isActive('Saturday') }"
      @click="toggleDaySelected('Saturday')"
      >L</span
    >
    <span
      :class="{ active: isActive('Sunday') }"
      @click="toggleDaySelected('Sunday')"
      >S</span
    >
  </div>
</template>

<script>
export default {
  props: {
    value: { required: true },
    disabled: { required: false, default: false },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    toggleDaySelected(day) {
      if (this.disabled) return;
      const exist = this.content.find((i) => i == day);
      if (exist) this.content.splice(this.content.indexOf(exist), 1);
      else this.content.push(day);
    },
    isActive(day) {
      return this.content.some((i) => i == day);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/colors.scss";
.wrap {
  display: flex;
  span {
    background: #f3f2f7;
    border-radius: 100%;
    font-size: 12px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &.active {
      background-color: $primary;
      font-weight: bold;
      color: white;
    }
  }
  &.disabled {
    opacity: 0.8;
  }
  &:not(.disabled) {
    span {
      &:hover {
        border: 2px solid $primary;
        cursor: pointer;
      }
    }
  }
  &.lg {
    span {
      font-size: 15px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>