<template>
  <div>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :taggable="taggable"
      :placeholder="placeholder"
      :label="label"
      :options="options"
      @option:created="handleOptionCreated"
      v-model="content"
      :reduce="reduce"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { uuid } from "vue-uuid";

export default {
  props: {
    value: { required: false },
    vuexNamespace: { required: false },
    placeholder: { required: false },
    label: {
      default: "name",
      required: false,
    },
    taggable: {
      required: false,
      default: true,
    },
    reduce: {
      required: false
    },
    selectedId: { required: false },
  },
  components: { vSelect },
  created() {  
  },
  data() {
    return {
      content: this.value,
    };
  },
  mounted() {
    this.$store.dispatch(`${this.vuexNamespace}/fetchList`);
  },
  computed: {
    options() {
      return this.$store.getters[`${this.vuexNamespace}/list`];
    },
  },
  methods: {
    handleOptionCreated(option) {
      if (typeof option !== "object") {
        option = { id: uuid.v4(), [this.label]: option };
      }
      this.$store.dispatch(`${this.vuexNamespace}/create`, {
        data: option,
        resourceId: option.id,
      });
      this.content = option;
      this.handleInput();
    },
    handleInput() {
      this.$emit("input", this.content);
    },
    setContent(value) {
      this.content = value;
    },
  },
  watch: {
    content(newValue, oldValue) {
      if (newValue === oldValue || typeof newValue !== "object") return;
      this.handleInput();
    },
    options(newValue, oldValue) {
      if (this.selectedId == undefined) return;
      let elm = this.options.find((e) => e.id == this.selectedId);
      if (elm == undefined) return;

      this.setContent(elm);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 