<template>
  <div>
    <b-modal
      id="booststrapmodal"
      :title="T('Web.Generic.Suppliers.CreateSupploer', 'Create new collector')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
    >
      <!-- Body -->
      <b-form @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="T('Web.Generic.Cvr', 'Cvr')" label-for="cvr">
              <b-form-input autofocus id="cvr" v-model="collectorData.cvr" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="T('Web.Generic.Name', 'Name')"
              label-for="name"
            >
              <b-form-input id="name" v-model="collectorData.contactName" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="T('Web.Generic.Email', 'Email')"
              label-for="email"
            >
              <b-form-input id="email" v-model="collectorData.contactEmail" />
            </b-form-group>
            <b-form-group
              :label="T('Web.Generic.Phone', 'Phone')"
              label-for="phone"
            >
              <b-form-input id="phone" v-model="collectorData.contactPhone" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- End Body -->

      <!-- Footer -->
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="!canSubmit || submitting"
            @click="handleSubmit"
          >
            {{ T("Web.Generic.Create", "Create") }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ T("Web.Generic.Cancel", "Cancel") }}
          </b-button>
        </div>
      </template>
      <!-- End footer -->
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      visible: false,
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      collectorData: {
        contactName: undefined,
        contactEmail: undefined,
        contactPhone: undefined,
        cvr: undefined,
      },
    };
  },
  methods: {
    ...mapActions({ createCollector: "collectors/create" }),
    open(name) {
      this.visible = true;
      this.collectorData.contactName = name;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
      this.collectorData = {
        contactName: undefined,
        contactEmail: undefined,
        contactPhone: undefined,
        cvr: undefined,
      };
    },
    valueIsNotEmptyOrUnknown(value) {
      return value && value != "Unknown";
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        const id = uuid.v4();
        await this.createCollector({
          data: this.collectorData,
          resourceId: id,
        });
        this.resolvePromise({ id: id });
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
  computed: {
    canSubmit() {
      return (
        this.valueIsNotEmptyOrUnknown(this.collectorData.contactEmail) &&
        this.valueIsNotEmptyOrUnknown(this.collectorData.contactName) &&
        this.valueIsNotEmptyOrUnknown(this.collectorData.cvr)
      );
    },
  },
};
</script>