import BaseService from "../base/base.service";

export default class ImagesService extends BaseService {
    constructor(uri = null) {
        super((uri != null) ? uri :'images')
    }
    async uploadSingle(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return await this.post("", formData);
    }
}