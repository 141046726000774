<template>
  <dragablemodal
    :isDraggable="false"
    :name="'uploadCoverModal'"
    ref="uploadCoverModal"
    :height="'auto'"
    :width="400"
  >
    <div class="modals-content">
      <b-form @submit.stop.prevent="handleSubmit">
        <b-form-file
          @change="handleImageChanged"
          v-if="fileInputVisible"
          v-model="originFile"
        />
        <template v-else>
          <vue-croppie
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :boundary="{ width: '100%', height: 300 }"
            :viewport="{ width: 380, height: 260 }"
            v-model="orientation"
          >
          </vue-croppie>
          <div class="rotate-controls">
            <feather-icon
              icon="RotateCcwIcon"
              @click="rotateLeft"
              class="rotate-action"
            />
            <feather-icon
              icon="RotateCwIcon"
              @click="rotateRight"
              class="rotate-action"
            />
          </div>
        </template>
      </b-form>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit || submitting"
          @click="handleSubmit"
        >
          {{ $t("Generic.CreateBtn") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ $t("Generic.Cancel") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";

import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormFile,
    dragablemodal,
  },
  prop: ["value"],
  data() {
    return {
      submitting: false,
      fileInputVisible: true,
      content: this.value,
      originFile: undefined,
      orientation: 8,
    };
  },
  computed: {
    canSubmit() {
      return !this.fileInputVisible;
    },
  },
  methods: {
    async open() {
      this.$refs.uploadCoverModal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.$refs.uploadCoverModal.hide();
      this.image = undefined;
      this.fileInputVisible = true;
    },

    async handleSubmit() {
      let options = {
        type: "base64",
        size: { width: 600, height: 450 },
        format: "jpeg",
      };
      await this.$refs.croppieRef.result(options, (output) => {
        const file = this.dataURLtoFile(
          (this.croppieImage = output),
          this.originFile.name
        );
        this.content = file;
      });
      this.$emit("input", this.content);
      this.resolvePromise(true);
      this.handleModalHidden();
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },

    rotateLeft() {
      this.$refs.croppieRef.rotate(-90);
    },

    rotateRight() {
      this.$refs.croppieRef.rotate(90);
    },

    handleImageChanged(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };
      reader.readAsDataURL(files[0]);
      this.fileInputVisible = false;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  watch: {
    image() {
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.$refs.croppieRef.bind({
          url: rawImg,
        });
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate-controls {
  display: flex;
  justify-content: space-between;
}
</style>