<template>
  <div>
    <b-row class="head">
      <b-col cols="3" class="p-0">
        <infocard-cover-picker v-model="content.coverPath" />
      </b-col>
      <b-col cols="9" class="p-0">
        <infocard-map-position-picker
          :lng="content.longitude"
          :lat="content.latitude"
          @locationUpdated="handleLocationUpdatedFromMap"
          class="mb-1"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="7">
        <b-form-group :label="T('Web.Generic.Name') + ' *'">
          <b-form-input v-model="content.name" required autofocus />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group :label="T('Web.Generic.Locations.Location')">
          <v-select
            :options="locations"
            label="name"
            v-model="content.locationId"
            :reduce="(location) => location.id"
            :clearable="false"
            @option:selected="
              (location) => {
                content.longitude = location.longitude;
                content.latitude = location.latitude;
              }
            "
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import InfocardMapPositionPicker from "./InfocardMapPositionPicker.vue";
import InfocardCoverPicker from "./InfocardCoverPicker.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BButton,
    InfocardMapPositionPicker,
    InfocardCoverPicker,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
      showInfocardLocationPicker: false,
    };
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    selectedLocation() {
      return this.locations.find((l) => l.id == this.content.locationId);
    },
  },
  methods: {
    handleLocationUpdatedFromMap(e) {
      if (this.content.latitude != e.lat) this.content.latitude = e.lat;
      if (this.content.longitude != e.lng) this.content.longitude = e.lng;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  .head {
    margin-top: -1rem;
    margin-bottom: 20px;
    border-bottom: 2px solid #f4f4fb;
  }
}
</style>