<template>
  <div class="account-posting-list">
    <div
      class="inner"
      v-for="(postings, postingType) in postingsOrderdByPostingType"
      :key="postingType"
    >
      <label>{{ T("Web.Generic.PostingCategories." + postingType) }}</label>
      <div id="posting-container" class="posting-list">
        <!--  <transition-group name="fade" :key="postingType"> -->
        <div></div>
        <div
          :id="'posting|' + posting.postingSubjectLineId"
          class="posting-list-row"
          v-for="posting in postings.sort(
            (a, b) => new Date(a.postingDate) - new Date(b.postingDate)
          )"
          :key="posting.id"
          :class="{
            highligted: highlightedLineId == posting.postingSubjectLineId,
          }"
          @mouseenter="handlePostingMouseEnter(posting)"
          @mouseleave="handlePostingMouseLeave"
        >
          <div>{{ format_date(posting.postingDate) }}</div>
          <div v-b-tooltip.hover.top="posting.postingDescription">
            {{ posting.postingDescription }}
          </div>
          <div>{{ posting.postingQuantity | toCurrency }}</div>
          <div>
            {{ T("Web.Generic.Units." + posting.postingUnit) }}
          </div>
          <div>{{ posting.postingUnitPrice | toCurrency }}</div>
          <div>{{ posting.postingTotalPrice | toCurrency }}</div>
          <div class="d-flex flex-row-reverse">
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown ml-1"
              toggle-class="p-0"
              @click.prevent
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="15"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item @click.prevent="handlePostingClick(posting)">
                {{ T("Web.Generic.Edit") }}
                <feather-icon icon="Edit3Icon" class="float-right" />
              </b-dropdown-item>
              <b-dropdown-item
                @click.stop="
                  $emit('remove-posting-clicked', {
                    id: posting.id,
                    accountId: posting.postingDestinationInfoCardId,
                    invoiceLineId: posting.postingSubjectLineId,
                  })
                "
                ><span class="text-danger">
                  {{ T("Web.Generic.Delete") }}
                  <feather-icon icon="TrashIcon" class="float-right" /> </span
              ></b-dropdown-item>
            </b-dropdown>
            <span>
              <feather-icon
                class="ml-1 text-primary"
                @mouseenter.stop="
                  handleLineScroll('line|' + posting.postingSubjectLineId)
                "
                icon="SearchIcon"
                size="16"
              />
            </span>
            <span>
              <feather-icon
                v-if="posting.isSplitPosting"
                class="ml-1 text-primary"
                icon="ShuffleIcon"
                size="16"
                v-b-tooltip.hover.right="
                  T('Web.Generic.Postings.SplitPosting', 'Split posting')
                "
              />
            </span>
            <span>
              <feather-icon
                v-if="posting.isSystemGenerated"
                class="ml-1 text-primary"
                icon="FlagIcon"
                size="16"
                v-b-tooltip.hover.right="
                  T('Web.Generic.Postings.SystemSuggested', 'System suggested')
                "
              />
            </span>
          </div>
        </div>
        <!--   </transition-group>-->
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BDropdown, BDropdownItem } from "bootstrap-vue";
import { uuid } from "vue-uuid";
export default {
  props: {
    postings: {
      required: true,
    },
    highlightedLineId: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  components: {
    VBTooltip,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {};
  },
  computed: {
    postingsOrderdByPostingType() {
      return this.postings.reduce((a, b) => {
        (a[b.postingCategory] = a[b.postingCategory] || []).push(b);
        return a;
      }, {});
    },
  },
  methods: {
    handleLineScroll(lineId) {
      this.$emit("posting-scroll-event", lineId);
    },
    handlePostingMouseEnter(posting) {
      this.$emit("posting-hover-enter", posting);
    },
    handlePostingMouseLeave() {
      this.$emit("posting-hover-leave", null);
    },
    handlePostingClick(posting) {
      this.$emit("posting-clicked", posting);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/colors.scss";

body {
  &.dark-layout {
    .account-posting-list {
      .inner {
        background-color: #283046;
      }

      .posting-list {
        > div {
          &:nth-of-type(odd) {
            background-color: #1b2234;
          }
        }
      }
      label {
        background-color: #343d55;
      }
      .posting-list {
        div {
          &.highligted {
            background-color: #89722e;
          }
        }
      }
    }
  }
}

.flex-row-reverse {
  span {
    width: 20%;
    svg {
      max-width: 90%;
    }
  }
}

.account-posting-list {
  .inner {
    margin: 10px;
  }
  label {
    text-transform: uppercase;
    border-bottom: 2px solid #cadac8;
    display: block;
    padding: 2px;
    padding-left: 7px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .posting-list {
    div {
      &.posting-list-row {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #dfdfdf66;
        display: flex;
        justify-content: space-between;
        position: relative;
        font-size: 12px;
        &.highligted {
          background-color: #ffe083;
        }
        &:hover {
          .delete-icon {
            visibility: visible;
          }
          background-color: #fafafc;
        }
        .delete-icon {
          visibility: hidden;
        }
        div {
          &:nth-child(2) {
            width: 20%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          width: 15%;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>