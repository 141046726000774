<template>
  <div>
    <div
      class="invoice-table"
      v-for="(entries, invoiceNumber) in entriesGroupedByInvoiceId"
      :key="invoiceNumber"
    >
      <content-modal
        :id="`reconciliation-${entries[0].invoiceId}-${month.month}-${month.year}-edit-${category}`"
        :title="invoiceNumber"
        body-class="p-0"
        size="xl"
      >
        <edit-reconciliation-modal-inner
          :reconciliationId="entries[0].invoiceId"
          @reconciliation-saved="closeReconciliationEdit(entries[0].invoiceId)"
        />
      </content-modal>

      <div class="invoice-number">
        <feather-icon icon="FileTextIcon" />
        <a
          @click.prevent="openReconciliationEditModal(entries[0].invoiceId)"
          href="#"
          >#{{ invoiceNumber }}</a
        >
      </div>
      <div
        v-for="(row, infocardName) in groupEntriesByInfocardName(entries)"
        :key="infocardName"
        class="invoice-row"
      >
        <div class="infocard-name">
          {{ infocardName }}
        </div>
        <div class="infocard-rows">
          <div class="infocard-item" v-for="(item, index) in row" :key="index">
            <div class="date-line">
              {{ format_date(item.entryDate) }}
            </div>
            <div class="inner d-flex">
              <span></span>
              <span
                >{{ to3Decimal(item.quantity) }}
                {{ T(`Web.Generic.Units.${item.unit}`) }}</span
              >
              <span>{{ item.unitPrice | toCurrency }}</span>
              <span>{{ item.totalPrice | toCurrency }}</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentModal from "@/app/common/modals/ContentModal.vue";
import EditReconciliationModalInner from "./EditReconciliationModalInner.vue";
export default {
  components: { ContentModal, EditReconciliationModalInner },
  props: {
    category: {
      required: true,
    },
    month: {
      required: true,
    },
    entries: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loading = true;
  },
  computed: {
    entriesGroupedByInvoiceId() {
      return groupBy(this.entries, "invoiceNumber");
    },
  },
  methods: {
    groupEntriesByInfocardName(entries) {
      return groupBy(entries, "infocardName");
    },
    closeReconciliationEdit(reconciliationId) {
      this.$bvModal.hide(
        `reconciliation-${reconciliationId}-${this.month.month}-${this.month.year}-edit-${this.category}`
      );
    },
    async openReconciliationEditModal(reconciliationId) {
      this.$bvModal.show(
        `reconciliation-${reconciliationId}-${this.month.month}-${this.month.year}-edit-${this.category}`
      );
    },
    to3Decimal(amount) {
      if (!amount || amount == null || amount == undefined || amount == "")
        return 0;

      const options = {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      };
      return amount.toLocaleString("da-DK", options);
    },
  },
};

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
</script>
<style lang="scss" scoped>
.invoice-table {
  margin-left: 104px;
  border-bottom: 2px solid #d7d7df;
  .invoice-number {
    width: 100%;
    border-bottom: 2px solid #d7d7df;
    font-size: 12px;
    font-weight: bold;
  }
  .invoice-row {
    display: flex;
    border-right: 2px solid #d7d7df;

    .infocard-name {
      border-right: 2px solid #d7d7df;
      width: 200px;
      font-weight: bold;
      font-size: 15px;
      text-overflow: ellipsis;
      padding-left: 5px;
    }
    .infocard-rows {
      width: 100%;

      .date-line {
        width: 100%;
        background-color: #f4f4fb;
        font-size: 11px;
        font-weight: bold;
        padding-left: 5px;
        border-bottom: 1px solid #d7d7df;
        &:not(:first-child) {
          border-top: 1px solid #d7d7df;
        }
      }

      .inner {
        width: calc(100% + 291px);
        margin-left: -291px;
        padding: 0px 10px;
        span {
          text-align: center;
          width: 24%;
          &:last-child {
            width: 4%;
          }
        }
      }
    }
  }
}
</style>