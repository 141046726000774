<template>
  <form @submit.stop.prevent="handleSubmit">
    <!--
    <h6 class="my-2 text-muted">
      {{ $t("ContainerInfoCardForm.Info.Header") }}
    </h6>
    <b-card>
      <b-row>
        <b-col cols="12" sm="4">
          <b-form-group class="d-flex justify-content-start">
            <cover-picker v-model="selectedCoverFile" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="8">
          <b-form-group :label="$t('Generic.Name')">
            <b-form-input v-model="container.name" required autofocus />
          </b-form-group>
          <b-form-group :label="$t('ContainerInfoCardForm.Info.Location')">
            <location-picker-select
              :selected-id="container.locationId"
              v-model="selectedLocation"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12">
          <b-form-group :label="$t('ContainerInfoCardForm.Info.Placenemt')">
            <b-form-input v-model="container.placement" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12">
          <location-map-selector
            @locationUpdated="handleLocationUpdated"
            :lng="container.longitude"
            :lat="container.latitude"
          />
        </b-col>
      </b-row>
    </b-card>
    <h6 class="my-2 text-muted">
      {{ $t("ContainerInfoCardForm.Collector.Header") }}
    </h6>
    <b-card>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('ContainerInfoCardForm.Collector.Collector')"
          >
            <collector-picker-select
              v-model="selectedCollector"
              :selected-id="container.collectorId"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('ContainerInfoCardForm.Collector.RecivingFacilities')"
          >
            <appendable-select
              :vuex-namespace="'recivingFacilities'"
              v-model="selectedRecivingFacility"
              :selected-id="container.receivingFacilityId"
              :placeholder="
                $t(
                  'ContainerInfoCardForm.Collector.RecivingFacilitiesPlaceholder'
                )
              "
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('ContainerInfoCardForm.Collector.ReceiverRef')"
          >
            <b-form-textarea v-model="container.receiverRef" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('ContainerInfoCardForm.Collector.Pickupdays')"
          >
            <weekday-picker class="lg" v-model="container.pickupDays" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <transition name="fade" mode="out-in">
      <div v-if="collapsed">
        <b-card class="expand-btn" @click="collapsed = false">
          <div class="text-muted text-center">
            {{ $t("ContainerInfoCardForm.ShowAllBtn") }}
          </div>
        </b-card>
      </div>
      <div v-else>
        <h6 class="my-2 text-muted">
          {{ $t("ContainerInfoCardForm.Specific.Header") }}
        </h6>
        <b-card>
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                :label="$t('ContainerInfoCardForm.Specific.Containertype')"
              >
                <appendable-select
                  vuex-namespace="containerTypes"
                  v-model="selectedContainerType"
                  :selected-id="container.containerTypeId"
                  :placeholder="
                    $t(
                      'ContainerInfoCardForm.Specific.ContainertypePlaceholder'
                    )
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group :label="$t('Generic.Size')">
                <b-row>
                  <b-col cols="9">
                    <b-form-input type="number" v-model="container.size" />
                  </b-col>
                  <b-col cols="3">
                    <b-form-select
                      :options="options.sizeUnits"
                      v-model="container.sizeUnit"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <h6 class="my-2 text-muted">
          {{ $t("ContainerInfoCardForm.Content.Header") }}
        </h6>
        <b-card>
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                :label="$t('ContainerInfoCardForm.Content.Category')"
              >
                <b-form-select
                  :options="options.wasteCategory"
                  v-model="container.wasteCategory"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group
                :label="$t('ContainerInfoCardForm.Content.Fraction')"
              >
                <appendable-select
                  vuex-namespace="wasteFractions"
                  v-model="selectedFraction"
                  :selected-id="container.wasteFractionId"
                  :placeholder="
                    $t('ContainerInfoCardForm.Content.FractionPlaceholder')
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('ContainerInfoCardForm.Content.EAK')">
                <treeselect
                  :multiple="false"
                  :options="ewcGroupList"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  v-model="selectedEwcCode"
                  :placeholder="
                    $t('ContainerInfoCardForm.Content.EAKPlaceholder')
                  "
                  search-nested
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </transition>
    -->
  </form>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import CoverPicker from "@/components/forms/infoCards/coverPicker/CoverPicker.vue";
import LocationPickerSelect from "./LocationPickerSelect.vue";
import CollectorPickerSelect from "@/components/forms/infoCards/CollectorPickerSelect.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import AppendableSelect from "@/components/select/AppendableSelect.vue";
import ImagesService from "@/services/files/images-service";
import { mapActions, mapGetters } from "vuex";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { uuid } from "vue-uuid";
import LocationMapSelector from '@/components/google-maps/LocationMapSelector.vue';

export default {
  props: {
    location: {
      require: false,
    },
    infoCard: {
      required: false,
    },
    resourceId: {
      required: true,
    },
    name: {
      default: undefined,
    },
    supplierContactId: {
      default: undefined,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    Treeselect,
    CoverPicker,
    LocationPickerSelect,
    CollectorPickerSelect,
    WeekdayPicker,
    AppendableSelect,
    LocationMapSelector,
  },
  created() {
    this.fetchEwcs();
    if (this.infoCard) {
      this.container = this.infoCard;
    }
    if (this.location) {
      this.container.locationId = this.location.id;
    }
    if (this.name) {
      this.container.name = this.name;
    }
    if (this.supplierContactId) {
      this.container.collectorId = this.supplierContactId;
    }
  },
  data() {
    return {
      collapsed: true,
      locationLocked: true,
      selectedCoverFile: undefined,
      selectedLocation: undefined,
      selectedCollector: undefined,
      selectedRecivingFacility: undefined,
      selectedContainerType: undefined,
      selectedFraction: undefined,
      selectedEwcCode: undefined,
      container: {
        type: "ContainerInfoCard",
        name: undefined,
        placement: undefined,
        receiverRef: undefined,
        size: undefined,
        wasteCategory: "None",
        coverPath: undefined,
        sizeUnit: "Cubic",
        pickupDays: [],
        ewcCodeId: undefined,
        wasteFractionId: undefined,
        collectorId: undefined,
        receivingFacilityId: undefined,
        containerTypeId: undefined,
        locationId: undefined,
      },
      options: {
        sizeUnits: [
          { text: "m3", value: "Cubic" },
          { text: "L", value: "Litre" },
        ],
        wasteCategory: [
          { text: this.$t("Generic.WasteCategoryNone"), value: "None" },
          { text: this.$t("Generic.WasteCategoryRezycl"), value: "Rezycl" },
          { text: this.$t("Generic.WasteCategoryReuse"), value: "Reuse" },
          {
            text: this.$t("Generic.WasteCategoryCombostion"),
            value: "Combostion",
          },
          { text: this.$t("Generic.WasteCategorySpecial"), value: "Special" },
          { text: this.$t("Generic.WasteCategoryLandfill"), value: "Landfill" },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      createInfoCard: "infoCards/create",
      fetchEwcs: "ewcs/fetchList",
    }),
    handleLocationUpdated(data) {
      this.container.latitude = data.lat;
      this.container.longitude = data.lng;
    },
    async submit() {
      if (this.selectedCoverFile) {
        const imgService = new ImagesService();
        this.container.coverPath = (
          await imgService.uploadSingle(this.selectedCoverFile)
        ).data.path;
      }
      await this.createInfoCard({
        data: this.container,
        resourceId: this.resourceId,
      });
    },
    getEwcGroupeList(searchTerm = "") {
      let baseList = this.ewcs;
      if (searchTerm != "") {
        baseList = this.ewcs.filter(
          (e) =>
            e.code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.eCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.hCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }

      const list = baseList
        .filter((e) => e.alias == null)
        .reduce(function (rv, x) {
          (rv[x["categoryName"]] = rv[x["categoryName"]] || {
            label: x["categoryName"],
            children: [],
            id: uuid.v4(),
            isDefaultExpanded: searchTerm != "",
          }).children.push({
            id: x.id,
            code: x.code,
            label: `${x.code} | ${x.name}`,
          });
          return rv;
        }, {});

      let resultList = Object.keys(list).map(function (key) {
        return list[key];
      });
      let filledEwcItems = [];
      baseList
        .filter((e) => e.alias != null)
        .forEach((item) =>
          filledEwcItems.push({
            id: item.id,
            code: item.code,
            label: `${item.code} | ${item.alias}`,
          })
        );
      resultList.unshift({
        id: "filled",
        label: "Filled",
        children: filledEwcItems,
        isDefaultExpanded: true,
      });
      return resultList;
    },
  },
  computed: {
    ...mapGetters({ ewcs: "ewcs/list" }),
    ewcGroupList() {
      return this.getEwcGroupeList("");
    },
    canSubmit() {
      return (
        this.valueIsNotEmptyOrUnknown(this.container.name) &&
        this.valueIsNotEmptyOrUnknown(this.container.locationId) &&
        this.valueIsNotEmptyOrUnknown(this.container.collectorId)
      );
    },
  },
  watch: {
    selectedEwcCode(newValue) {
      if (!newValue) return;
      this.container.ewcCodeId = newValue;
    },
    selectedFraction(newValue) {
      if (!newValue) return;
      this.container.wasteFractionId = newValue.id;
    },
    selectedCollector(newValue) {
      if (!newValue) return (this.container.collectorId = undefined);
      this.container.collectorId = newValue.id;
    },
    selectedRecivingFacility(newValue) {
      if (!newValue) return;
      this.container.receivingFacilityId = newValue.id;
    },
    selectedContainerType(newValue) {
      if (!newValue) return;
      this.container.containerTypeId = newValue.id;
    },
    selectedLocation(newValue) {
      if (!newValue) return (this.container.locationId = undefined);
      this.container.locationId = newValue.id;
    },
    canSubmit(newValue) {
      this.$emit("can-submit-status-changed", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-btn {
  border: 2px solid #fff;
  &:hover {
    cursor: pointer;
    border: 2px solid #30cf84;
  }
}
</style>