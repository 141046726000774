<template>
  <dragable-modal
    class="dragable-modal"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <confirm-modal ref="confirmModal" />
    <b-overlay :show="submitting">
      <header class="modal-header">
        <h4 class="modal-title">
          {{
            isCreate
              ? T(
                  "Web.Generic.Infocards.CreateNewInfocard",
                  "Create new infocard"
                )
              : T("Web.Generic.Infocards.EditInfocard", "Edit infocard")
          }}
        </h4>
      </header>
      <div class="modals-content">
        <page-loader :loading="fetchingInfocardForEdit">
          <div>
            <transition name="fade" mode="out-in">
              <div v-if="infocardType == undefined">
                <h2 class="text-muted py-2 text-center">
                  {{
                    T(
                      "Web.Generic.Infocards.ChooseInfocardTypeToCreate",
                      "Choose infocard type to create"
                    )
                  }}
                </h2>
                <div class="info-card-type-picker pb-2">
                  <b-button
                    variant="outline-primary"
                    class="type-option"
                    @click="setInfocardType('ContainerInfoCard')"
                  >
                    <feather-icon icon="BoxIcon" size="36" stroke-width="1" />
                    <span>{{
                      T("Web.Generic.Infocards.Types.Container")
                    }}</span>
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="type-option"
                    @click="setInfocardType('AccountInfoCard')"
                  >
                    <feather-icon
                      icon="ClipboardIcon"
                      size="36"
                      stroke-width="1"
                    />
                    <span>{{ T("Web.Generic.Infocards.Types.Account") }}</span>
                  </b-button>
                </div>
              </div>

              <container-infocard-form
                v-else-if="infocardType == 'ContainerInfoCard'"
                :infocard="infocardData"
                @submittable-status-changed="
                  (submittable) => (canSubmit = submittable)
                "
              />
              <account-infocard-form
                v-else-if="infocardType == 'AccountInfoCard'"
                :infocard="infocardData"
                @submittable-status-changed="
                  (submittable) => (canSubmit = submittable)
                "
              />
            </transition>
          </div>
        </page-loader>
      </div>
      <footer class="modal-footer">
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            :disabled="!canSubmit || submitting"
            @click="handleSubmitAsync"
          >
            <span v-if="isCreate">
              {{
                submitting
                  ? T("Web.Generic.Creating", "Creating")
                  : T("Web.Generic.Create")
              }}
            </span>
            <span v-else>
              {{
                submitting
                  ? T("Web.Generic.Saving", "Saving")
                  : T("Web.Generic.Save", "Save")
              }}
            </span>
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleModalHidden"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
          <b-button
            variant="outline-danger"
            class="mr-2"
            @click="handleDeleteAsync"
            v-if="!isCreate"
          >
            {{ T("Web.Generic.Delete") }}
          </b-button>
        </div>
      </footer>
    </b-overlay>
  </dragable-modal>
</template>

<script>
import DragableModal from "@/app/components/layout/DragableModal.vue";
import { BButton, BOverlay } from "bootstrap-vue";
import ContainerInfocardForm from "./ContainerInfocardForm.vue";
import AccountInfocardForm from "./AccountInfocardForm.vue";
import { mapActions, mapGetters } from "vuex";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    DragableModal,
    BButton,
    BOverlay,
    ContainerInfocardForm,
    AccountInfocardForm,
    PageLoader,
    ConfirmModal,
  },
  data() {
    return {
      isCreate: true,
      infocardType: undefined,
      infocardData: null,
      canSubmit: false,
      fetchingInfocardForEdit: false,
      preDefinedName: null,
      preDefinedLocation: null,
      preDefinedCollectorId: null,
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  computed: {
    ...mapGetters({ getInfocardById: "infocards/byId" }),
    preDefinedLocationId() {
      return this.preDefinedLocation ? this.preDefinedLocation.id : null;
    },
    preDefinedlocationLng() {
      return this.preDefinedLocation
        ? this.preDefinedLocation.longitude
        : undefined;
    },
    preDefinedlocationLat() {
      return this.preDefinedLocation
        ? this.preDefinedLocation.latitude
        : undefined;
    },
  },
  methods: {
    ...mapActions({
      createInfocard: "infocards/create",
      updateInfocard: "infocards/update",
      fetchInfocard: "infocards/fetchSingle",
      archiveInfocard: "infocards/delete",
    }),
    async openCreate(location = null, name = "", collectorId = null) {
      this.isCreate = true;
      this.preDefinedLocation = location;
      this.preDefinedName = name;
      this.preDefinedCollectorId = collectorId;
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async openEdit(infocardId) {
      try {
        this.isCreate = false;
        this.fetchingInfocardForEdit = true;
        await this.fetchInfocard({ id: infocardId });
        this.infocardData = { ...this.getInfocardById(infocardId) };
        this.infocardType = this.infocardData.type;
      } finally {
        this.fetchingInfocardForEdit = false;
      }
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    handleModalHidden() {
      this.canSubmit = false;
      this.fetchingInfocardForEdit = false;
      this.infocardType = undefined;
      this.infocardData = null;
      this.$refs.dragablemodal.hide();
    },

    async handleSubmitAsync() {
      try {
        this.submitting = true;
        // Create or Update
        if (this.infocardData.id)
          await this.updateInfocard({ data: this.infocardData });
        else await this.createInfocard({ data: this.infocardData });
        // Close
        this.handleModalHidden();
        return this.resolvePromise(true);
      } finally {
        this.submitting = false;
      }
    },

    async handleDeleteAsync() {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T("Web.Generic.ArchiveConfirm")
        ))
      )
        return;
      try {
        this.submitting = true;
        await this.archiveInfocard({ resourceId: this.infocardData.id });
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },

    setInfocardType(type) {
      this.infocardType = type;

      if (type == "ContainerInfoCard") {
        this.infocardData = {
          name: this.preDefinedName ?? "",
          type: "ContainerInfoCard",
          locationId: this.preDefinedLocationId,
          coverPath: null,
          placement: "",
          collectorId: this.preDefinedCollectorId,
          latitude: this.preDefinedlocationLat,
          longitude: this.preDefinedlocationLng,
          wasteCategory: "None",
          pickupDays: [],
          receiverRef: "",
          containerSize: 0,
          containerSizeUnit: "Cubic",
          ewcCodeId: null,
          wasteFractionId: null,
          containerTypeId: null,
          receivingFacilityId: null,
        };
      } else {
        this.infocardData = {
          name: this.preDefinedName ?? "",
          type: "AccountInfoCard",
          locationId: this.preDefinedLocationId,
          coverPath: null,
          latitude: this.preDefinedlocationLat,
          longitude: this.preDefinedlocationLng,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card-type-picker {
  display: flex;
  justify-content: space-evenly;
  .type-option {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 17px;
      margin-top: 10px;
    }
  }
}
</style>

