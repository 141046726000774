<template>
  <div>
    <dragablemodal
      :name="'postingsplitmodal'"
      ref="dragablemodal"
      :height="'auto'"
      :width="900"
    >
      <header>
        {{
          T(
            "Web.Generic.Invoices.Reconciliations.PostingSplitPopup.Title",
            "Specify distribution"
          )
        }}
      </header>
      <div class="modals-content">
        <b-form @submit.stop.prevent v-if="line">
          <b-row>
            <b-col cols="12">
              <div class="line-preview">
                <span>{{ format_date(line.date) }}</span>
                <span
                  >{{ line.text }}<br />
                  <small>{{ line.description }}</small></span
                >
                <span>{{ line.amount | toCurrency }}</span>
                <span>{{ line.unit }}</span>
                <span>{{ line.priceAmount | toCurrency }}</span>
                <span>{{ line.priceTotal | toCurrency }}</span>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="postings-list">
                <ul>
                  <li v-for="(posting, index) in localPostings" :key="index">
                    <span>
                      {{
                        getAccountFromId(posting.postingDestinationInfoCardId)
                          .name
                      }}
                      ({{
                        getAccountFromId(posting.postingDestinationInfoCardId)
                          .refId
                      }})
                    </span>
                    <b-row>
                      <b-col cols="2">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineAmount')"
                          label-for="text-quantity"
                        >
                          <b-input-group
                            :append="unitTypeOptions[posting.postingUnit]"
                            class="mb-2 mr-sm-2 mb-sm-0"
                          >
                            <b-form-input
                              type="number"
                              id="text-quantity"
                              trim
                              :value="posting.postingQuantity"
                              @change="
                                (value) =>
                                  postingAmountChanged(
                                    posting.postingDestinationInfoCardId,
                                    value
                                  )
                              "
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col cols="2">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineUnit')"
                          label-for="unit-input"
                        >
                          <b-form-select
                            id="unit-input"
                            :options="unitTypeOptions"
                            v-model="posting.postingUnit"
                            disabled
                          ></b-form-select>
                          <small
                            class="missing-text"
                            v-if="
                              !valueIsNotEmptyOrUnknown(posting.postingUnit)
                            "
                            >{{
                              T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.UnitMissingText", "Provide unit")
                            }}</small
                          >
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineUnitPrice')"
                          label-for="total-amount"
                        >
                          <b-form-input
                            id="text-amount"
                            trim
                            :value="line.priceAmount | toCurrency"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineUnitTotal')"
                          label-for="total-amount"
                        >
                          <b-form-input
                            id="text-amount"
                            trim
                            :value="
                              ((line.priceTotal / line.amount) *
                                posting.postingQuantity)
                                | toCurrency
                            "
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineAmount')"
                          label-for="text-quantity"
                        >
                          <b-input-group
                            :append="unitTypeOptions[posting.postingUnit]"
                            class="mb-2 mr-sm-2 mb-sm-0"
                          >
                            <b-form-input
                              type="number"
                              id="text-quantity"
                              trim
                              :value="posting.postingSecondaryQuantity"
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col cols="2">
                        <b-form-group
                          :label="T('Web.Generic.Invoices.LineUnit')"
                          label-for="unit-input"
                        >
                          <b-form-select
                            id="unit-input"
                            :options="unitTypeOptions"
                            v-model="posting.postingSecondaryUnit"
                          ></b-form-select>
                          <small
                            class="missing-text"
                            v-if="
                              !valueIsNotEmptyOrUnknown(
                                posting.postingSecondaryUnit
                              )
                            "
                            >{{
                              T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.UnitMissingText", "Provide unit")
                            }}</small
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
                <h4 class="text-danger float-left" v-if="!splitIsValid">
                  {{T("Web.Generic.Invoices.Reconciliations.PostingSplitPopup.Error", "The distribution sum has to be equal to total sum")}}
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <footer class="modal-footer">
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="!splitIsValid"
            @click="handleSubmit"
          >
            {{ T("Web.Generic.Approve", "Approve") }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
        </div>
      </footer>
    </dragablemodal>
  </div>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";

import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BFormSelect,
  BInputGroup,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormSelect,
    BInputGroup,
    dragablemodal,
  },
  data() {
    return {
      line: null,
      postings: [],
      localPostings: [],
      resolvePromise: undefined,
      rejectPromise: undefined,
      unitTypeOptions: [
        {
          value: "Unknown",
          text: this.T("Web.Generic.Choose"),
          disabled: true,
        },
        {
          value: "Pcs",
          text: this.T("Web.Generic.Units.Pcs"),
        },
        {
          value: "Tonnage",
          text: this.T("Web.Generic.Units.Tonnage"),
        },
        {
          value: "Kg",
          text: this.T("Web.Generic.Units.Kg"),
        },
        {
          value: "Hours",
          text: this.T("Web.Generic.Units.Hours"),
        },
        {
          value: "Cubic",
          text: this.T("Web.Generic.Units.Cubic"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ allLocations: "locationsOld/list" }),
    canSubmit() {
      return true;
    },
    accounts() {
      console.log(this.allLocations);
      if (this.localPostings.length == 0) return [];
      return this.allLocations.reduce((x, y) => {
        const accounts = y.infoCards.filter((item) =>
          this.localPostings.find(
            (posting) => posting.postingDestinationInfoCardId == item.id
          )
        );
        return x.concat(accounts);
      }, []);
    },
    splitIsValid() {
      if (this.line == null) return 0;
      const lineTotal = this.line.amount;
      const postedTotal = this.localPostings.reduce(
        (total, posting) => (total += posting.postingQuantity),
        0
      );
      return lineTotal - postedTotal > -0.02 && lineTotal - postedTotal < 0.02;
    },
  },
  methods: {
    ...mapActions({ fetchLocations: "locationsOld/fetchList" }),
    async open(postingsList, line) {
      await this.fetchLocations({ include: ["infocards"] });
      this.line = line;
      this.postings = postingsList;
      this.localPostings = JSON.parse(JSON.stringify(postingsList));
      this.localPostings.forEach((p) => (p.isSplitPosting = true));
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    getAccountFromId(id) {
      console.log(this.accounts);
      return this.accounts.find((account) => account.id == id);
    },
    postingAmountChanged(accountId, newAmountValue) {
      const posting = this.localPostings.find(
        (item) => item.postingDestinationInfoCardId == accountId
      );
      const percentage = (newAmountValue / this.line.amount) * 100;
      const result = (this.line.priceTotal * percentage) / 100;
      posting.postingQuantity = parseFloat(newAmountValue);
      posting.postingTotalPrice = parseFloat(result);
    },
    clearForm() {
      this.resolvePromise = undefined;
      this.rejectPromise = undefined;
      this.line = null;
    },
    handleModalHidden() {
      this.clearForm();
      this.hideModal();
    },
    handleSubmit() {
      this.resolvePromise(this.localPostings);
      this.hideModal();
    },
    handleCancel() {
      this.resolvePromise(false);
      this.hideModal();
    },
    hideModal() {
      this.$refs.dragablemodal.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
.line-preview {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #ebe9f1;
  background-color: #fafafc;
  span {
    padding: 0.72rem 2rem;
  }
}
.postings-list {
  margin: 15px 0px;
  span {
    font-weight: bold;
    font-size: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
</style>