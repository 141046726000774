<template>
  <div>
    <b-table-simple small>
      <b-thead>
        <b-th>{{ T("Web.Generic.Date") }}</b-th>
        <b-th>{{ T("Web.Generic.Infocards.InfocardName") }}</b-th>
        <b-th>{{ T("Web.Generic.Infocards.InternalGroupId") }}</b-th>
        <b-th>{{ T("Web.Generic.Postings.Text") }}</b-th>
        <b-th>{{ T("Web.Generic.Postings.Quantity", "Quantity") }}</b-th>
        <b-th>{{ T("Web.Generic.Invoices.LineUnitPrice", "Unit price") }}</b-th>
        <b-th>{{ T("Web.Generic.Invoices.LineTotal") }}</b-th>
      </b-thead>
      <b-tbody>
        <template v-for="(postings, type) in postingsGroupedByType">
          <b-tr class="table-primary font-weight-bold" :key="type">
            <b-td colspan="4">{{
              T("Web.Generic.PostingCategories." + type)
            }}</b-td>
            <b-td></b-td>
            <b-td>{{
              getSummedUnitPriceForPostingType(type) | toCurrency
            }}</b-td>
            <b-td>{{
              getSummedTotalPriceForPostingType(type) | toCurrency
            }}</b-td>
          </b-tr>
          <b-tr v-for="posting in postings" :key="posting.id">
            <b-td>{{ format_date(posting.postingDate) }}</b-td>
            <b-td>{{ posting.infocardName }}</b-td>
            <b-td>{{
              getInfocardGroupId(posting.postingDestinationInfoCardId)
            }}</b-td>
            <b-td>{{ getInvoiceLineText(posting.postingSubjectLineId) }}</b-td>
            <b-td>
              <template v-if="posting.postingCategory == 'Reprocessing'"> -- </template>
              <template v-else>
                {{ posting.postingQuantity | toCurrency }}
                {{ T("Web.Generic.Units." + posting.postingUnit) }}
                <template v-if="posting.postingSecondaryQuantity > 0">
                  <br /><small>
                    {{ posting.postingSecondaryQuantity | toCurrency }}
                    {{ T("Web.Generic.Units." + posting.postingSecondaryUnit) }}
                  </small>
                </template>
              </template>
            </b-td>
            <b-td>{{ posting.postingUnitPrice | toCurrency }}</b-td>
            <b-td>{{ posting.postingTotalPrice | toCurrency }}</b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BTr,
  BTd,
  BCard,
} from "bootstrap-vue";
export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BCard,
    BTh,
    BTd,
    BTr,
  },
  props: {
    postings: {
      required: false,
      default: () => [],
    },
    infocards: {
      required: false,
      default: () => [],
    },
    lines: {
      required: false,
      default: () => [],
    },
  },
  computed: {
    postingsGroupedByType() {
      let list = this.postings.reduce((prev, curr) => {
        if (curr.postingCategory == undefined) return prev;
        if (prev[curr.postingCategory] == undefined) {
          prev[curr.postingCategory] = [];
        }
        curr.infocardName = this.getInfocardName(
          curr.postingDestinationInfoCardId
        );
        prev[curr.postingCategory].push(curr);
        return prev;
      }, {});

      Object.keys(list).forEach((key) => {
        list[key] = list[key].sort((a, b) =>
          a.infocardName.localeCompare(b.infocardName)
        );
      });
      return list;
    },
  },
  methods: {
    getInfocardName(id) {
      return this.infocards.find((i) => i.id == id)?.name;
    },
    getInfocardGroupId(id) {
      return this.infocards.find((i) => i.id == id)?.internalGroupId;
    },
    getInvoiceLineText(id) {
      return this.lines.find((i) => i.id == id)?.text;
    },
    getSummedQtyForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingQuantity + curr.postingSecondaryQuantity;
        return prev;
      }, 0);
    },
    getSummedUnitPriceForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingUnitPrice;
        return prev;
      }, 0);
    },
    getSummedTotalPriceForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingTotalPrice;
        return prev;
      }, 0);
    },
  },
};
</script>