<template>
  <div class="wrap">
    <img v-if="content" :src="`${apiBaseUrl()}/images/${content}`" />
    <img v-else src="@/assets/images/container-no-image.jpg" />
    <icon-badge
      class="edit-icon"
      icon="Edit2Icon"
      v-b-tooltip.hover.top="'Change image'"
      @click="openUploadModal"
    />
    <icon-badge
      class="remove-icon"
      icon="XIcon"
      v-b-tooltip.hover.top="'Remove image'"
      @click="removeImage"
    />
    <infocard-cover-upload-modal ref="uploadModal" v-model="content" />
  </div>
</template>

<script>
import IconBadge from "@/components/IconBadge.vue";
import { VBTooltip } from "bootstrap-vue";
import InfocardCoverUploadModal from "./InfocardCoverUploadModal.vue";

export default {
  components: {
    IconBadge,
    VBTooltip,
    InfocardCoverUploadModal,
  },
  props: {
    value: {
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    async openUploadModal() {
      const isSuccess = await this.$refs.uploadModal.open();
      if (isSuccess) this.$emit("input", this.content);
    },
    async removeImage() {
      this.content = null;
      this.$emit("input", this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  img {
    width: 100%;
  }
  .edit-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .remove-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
}
</style>