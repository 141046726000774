<template>
  <div class="d-flex justify-content-end">
    <upload-cover-modal ref="uploadCoverModal" v-model="content" />
    <img
      :src="base64"
      @click="openUploadModal"
      v-if="content"
      class="cover-preview"
    />
    <b-button v-else @click="openUploadModal" variant="outline-primary">
      {{ $t("Generic.AddCover") }}</b-button
    >
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import UploadCoverModal from "./UploadCoverModal.vue";
export default {
  props: { value: { required: true } },

  components: {
    BButton,
    UploadCoverModal,
  },
  created() {
    this.base64 = this.value;
  },
  data() {
    return {
      content: this.value,
      base64: null,
    };
  },

  methods: {
    handleInput() {
      var reader = new FileReader();
      reader.readAsDataURL(this.content);
      reader.onload = () => {
        this.base64 = reader.result;
        this.$emit("input", this.content);
      };
    },
    async openUploadModal() {
      const isSuccess = await this.$refs.uploadCoverModal.open();
      if (isSuccess) this.handleInput();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn,
img {
  width: 160px;
  height: 143px;
}
img {
  border-radius: 0.358rem;
  &:hover {
    cursor: pointer;
  }
}
</style>