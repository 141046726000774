<template>
  <dragablemodal
    :name="'createlocationModal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="400"
  >
    <header>
      {{ T("Web.Generic.Locations.CreateLocation", "Create location") }}
    </header>
    <div class="modals-content">
      <b-form @submit.stop.prevent="handleSubmit" v-if="locationData">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="T('Web.Generic.Name', 'Name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="locationData.name"
                :autofocus="locationData.name == ''"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="T('Web.Generic.Address', 'Address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="locationData.address"
                :autofocus="locationData.name != ''"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group :label="T('Web.Generic.Zip', 'Zip')" label-for="zip">
              <b-form-input id="zip" v-model="locationData.zip" />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              :label="T('Web.Generic.City', 'City')"
              label-for="city"
            >
              <b-form-input id="city" v-model="locationData.city" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="T('Web.Generic.PNo', 'P number')"
              label-for="pnumber"
            >
              <b-form-input id="pnumber" v-model="locationData.pnumber" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="T('Web.Generic.VatNo', 'Vat number')"
              label-for="vatnumber"
            >
              <b-form-input id="vatnumber" v-model="locationData.vatnumber" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit || submitting"
          @click="handleSubmit"
        >
          {{ T("Web.Generic.Create") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ T("Web.Generic.Cancel") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";

import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import { uuid } from "vue-uuid";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    dragablemodal,
  },
  data() {
    return {
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      locationData: {
        name: undefined,
        address: undefined,
        zip: undefined,
        city: undefined,
        pnumber: undefined,
        vatnumber: undefined,
      },
    };
  },
  computed: {
    canSubmit() {
      return (
        this.valueIsNotEmptyOrUnknown(this.locationData.name) &&
        this.valueIsNotEmptyOrUnknown(this.locationData.address) &&
        this.valueIsNotEmptyOrUnknown(this.locationData.zip) &&
        this.valueIsNotEmptyOrUnknown(this.locationData.city)
      );
    },
  },
  methods: {
    ...mapActions({ createLocation: "locations/create" }),
    async open(
      name = "",
      address = undefined,
      zip = undefined,
      city = undefined,
      vatnumber = undefined
    ) {
      this.locationData.name = name;
      this.locationData.address = address;
      this.locationData.zip = zip;
      this.locationData.city = city;
      this.locationData.vatnumber = vatnumber;
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.$refs.dragablemodal.hide();
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        const id = uuid.v4();
        await this.createLocation({
          data: this.locationData,
          resourceId: id,
        });
        this.resolvePromise({ id: id });
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
    valueIsNotEmptyOrUnknown(value) {
      return value && value != "Unknown";
    },
  },
};
</script>