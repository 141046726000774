<template>
  <div
    :id="'posting|' + posting.id"
    class="posting-list-row"
    :class="{ highligted: highlightedLineId == posting.postingSubjectLineId }"
    @mouseover="$emit('highlight-line', posting.postingSubjectLineId)"
    @mouseleave="$emit('highlight-line', null)"
  >
    <div>{{ format_date(posting.postingDate) }}</div>
    <div v-b-tooltip.hover.top="posting.postingDescription">
      {{ posting.postingDescription }}
    </div>
    <div>{{ posting.postingQuantity | toCurrency }}</div>
    <div>
      {{ T("Web.Generic.Units." + posting.postingUnit) }}
    </div>
    <div>{{ posting.postingUnitPrice | toCurrency }}</div>
    <div>{{ posting.postingTotalPrice | toCurrency }}</div>
    <div class="d-flex flex-row-reverse">
      <b-dropdown
        variant="link"
        no-caret
        right
        class="chart-dropdown ml-1"
        toggle-class="p-0"
        @click.prevent
        v-if="disabled == false"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="15"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click.prevent="handlePostingClick(posting)">
          {{ T("Web.Generic.Edit") }}
          <feather-icon icon="Edit3Icon" class="float-right" />
        </b-dropdown-item>
        <b-dropdown-item
          @click.stop="
            $emit('remove-posting-clicked', {
              id: posting.id,
              accountId: posting.postingDestinationInfoCardId,
              invoiceLineId: posting.postingSubjectLineId,
            })
          "
          ><span class="text-danger">
            {{ T("Web.Generic.Delete") }}
            <feather-icon icon="TrashIcon" class="float-right" /> </span
        ></b-dropdown-item>
      </b-dropdown>
      <span>
        <feather-icon
          class="ml-1 text-primary"
          icon="SearchIcon"
          size="16"
          @click.stop="$emit('scroll-to-line', posting.postingSubjectLineId)"
        />
      </span>
      <span>
        <feather-icon
          v-if="posting.isSplitPosting"
          class="ml-1 text-primary"
          icon="ShuffleIcon"
          size="16"
          v-b-tooltip.hover.top="
            T('Web.Generic.Postings.SplitPosting', 'Split posting')
          "
        />
      </span>
      <span>
        <feather-icon
          v-if="posting.isSystemGenerated"
          class="ml-1 text-primary"
          icon="FlagIcon"
          size="16"
          v-b-tooltip.hover.top="
            T('Web.Generic.Postings.SystemSuggested', 'System suggested')
          "
        />
      </span>
    </div>
  </div>
</template>
<script>
import { VBTooltip, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  props: {
    posting: {
      required: true,
    },
    highlightedLineId: {
      required: false,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  components: {
    VBTooltip,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    handleLineScroll(lineId) {
      this.$emit("posting-scroll-event", lineId);
    },
    handlePostingMouseEnter(posting) {
      this.$emit("posting-hover-enter", posting);
    },
    handlePostingMouseLeave() {
      this.$emit("posting-hover-leave", null);
    },
    handlePostingClick(posting) {
      this.$emit("posting-clicked", posting);
    },
    test() {
      console.log("tester");
    },
  },
};
</script>
<style lang="scss" scoped>
.posting-list-row {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #dfdfdf66;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 12px;
  &.highligted {
    background-color: #ffe083;
  }
  &:hover {
    .delete-icon {
      visibility: visible;
    }
    background-color: #fafafc;
  }
  .delete-icon {
    visibility: hidden;
  }
  div {
    &:nth-child(2) {
      width: 20%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    width: 15%;
    justify-content: space-between;
  }
}
</style>