<template>
  <div class="content-inner">
    <div class="d-flex justify-content-between align-items-center">
      <span style="width: 300px">Gruppér efter</span>
      <b-form-select
        size="sm"
        v-model="groupeUnit"
        :options="[
          { value: 'INFOCARDS', text: 'Infocards' },
          { value: 'FRACTIONS', text: 'Fractions' },
        ]"
      />
    </div>
    <hr />
    <div class="posting-list">
      <div
        v-for="(categoryPostings, category) in postingsGroupedByType"
        :key="category"
        class="posting-groupe"
      >
        <div @click="handleCategoryClicked(category)">
          <div style="font-weight-bold">
            {{ T("Web.Generic.PostingCategories." + category) }}
          </div>
          <div>
            <div
              v-if="
                [
                  'WasteCollection',
                  'Rent',
                  'WrongSorting',
                  'Charge',
                  'Income',
                  'FutileDriving',
                  'DeliveryOrRepatriation',
                  'Exchange',
                  'GoodsPurchase',
                  'RepairPurchase',
                  'SuppliesPurchase',
                  'ContainerWash',
                  'OtherFees',
                  'OilSurchargeFee',
                  'RegistrationFee',
                ].includes(category)
              "
            >
              <span
                >{{
                  formatCurrencyString(
                    getPostingsTotalFromUnit(categoryPostings, "Pcs"),
                    1
                  )
                }}
              </span>
              <span
                v-if="
                  !['Charge', 'Income', 'WasteCollection'].includes(category)
                "
              >
                {{ T("Web.Generic.Units.Pcs") }}</span
              >
              <span v-else>
                <feather-icon
                  v-b-tooltip.hover.top="
                    T('Web.Generic.PostingCategories.WasteCollection')
                  "
                  icon="TruckIcon"
              /></span>
            </div>
            <div
              v-if="
                [
                  'WasteCollection',
                  'WrongSorting',
                  'Income',
                  'Charge',
                  'DeliveryOrRepatriation',
                  'Exchange',
                ].includes(category)
              "
            >
              <span>{{
                formatCurrencyString(
                  getPostingsTotalTonnage(categoryPostings),
                  3
                )
              }}</span>
              <span> Ton</span>
            </div>

            <div
              v-if="
                [
                  'WasteCollection',
                  'WrongSorting',
                  'Income',
                  'Charge',
                  'FutileDriving',
                  'DeliveryOrRepatriation',
                  'Exchange',
                ].includes(category)
              "
            >
              <span>{{
                formatCurrencyString(
                  getPostingsTotalFromUnit(categoryPostings, "Hours"),
                  1
                )
              }}</span>
              <span> {{ T("Web.Generic.Units.Hours") }}</span>
            </div>

            <div>
              <span>{{
                formatCurrencyString(
                  getTotalPostingsPrice(categoryPostings, "Pcs"),
                  2
                )
              }}</span>
              <span> DKK</span>
            </div>
          </div>
          <div>
            <feather-icon
              icon="ChevronUpIcon"
              v-if="activeCategory == category"
            /><feather-icon icon="ChevronDownIcon" v-else />
          </div>
        </div>
        <div v-if="activeCategory == category">
          <div
            v-for="(infocardPostings, infocardId) in postingGroupedByInfocards(
              categoryPostings
            )"
            :key="`${category}_${infocardId}`"
          >
            <div>
              <div>{{ getInfocardName(infocardId) }}</div>
              <div>
                <div
                  v-if="
                    [
                      'WasteCollection',
                      'Rent',
                      'WrongSorting',
                      'Charge',
                      'Income',
                      'FutileDriving',
                      'DeliveryOrRepatriation',
                      'Exchange',
                      'GoodsPurchase',
                      'RepairPurchase',
                      'SuppliesPurchase',
                      'ContainerWash',
                      'OtherFees',
                      'OilSurchargeFee',
                      'RegistrationFee',
                    ].includes(category)
                  "
                >
                  <span
                    >{{
                      formatCurrencyString(
                        getPostingsTotalFromUnit(infocardPostings, "Pcs"),
                        1
                      )
                    }}
                  </span>
                  <span
                    v-if="
                      !['Charge', 'Income', 'WasteCollection'].includes(
                        category
                      )
                    "
                  >
                    {{ T("Web.Generic.Units.Pcs") }}
                  </span>
                  <span v-else>
                    <feather-icon
                      v-b-tooltip.hover.top="
                        T('Web.Generic.PostingCategories.WasteCollection')
                      "
                      icon="TruckIcon"
                  /></span>
                </div>
                <div
                  v-if="
                    [
                      'WasteCollection',
                      'WrongSorting',
                      'Income',
                      'Charge',
                      'DeliveryOrRepatriation',
                      'Exchange',
                    ].includes(category)
                  "
                >
                  <span>{{
                    formatCurrencyString(
                      getPostingsTotalTonnage(infocardPostings),
                      3
                    )
                  }}</span>
                  <span> Ton</span>
                </div>

                <div
                  v-if="
                    [
                      'WasteCollection',
                      'WrongSorting',
                      'Income',
                      'Charge',
                      'FutileDriving',
                      'DeliveryOrRepatriation',
                      'Exchange',
                    ].includes(category)
                  "
                >
                  <span>{{
                    formatCurrencyString(
                      getPostingsTotalFromUnit(infocardPostings, "Hours"),
                      1
                    )
                  }}</span>
                  <span> {{ T("Web.Generic.Units.Hours") }}</span>
                </div>
                <div>
                  <span>{{
                    formatCurrencyString(
                      getTotalPostingsPrice(infocardPostings, "Pcs"),
                      2
                    )
                  }}</span>
                  <span> DKK</span>
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <div
                v-for="infocardPosting in infocardPostings"
                :key="infocardPosting.id"
              >
                <div>{{ format_date(infocardPosting.postingDate) }}</div>
                <div>
                  <div
                    v-if="
                      [
                        'WasteCollection',
                        'Rent',
                        'WrongSorting',
                        'Income',
                        'Charge',
                        'FutileDriving',
                        'DeliveryOrRepatriation',
                        'Exchange',
                        'GoodsPurchase',
                        'RepairPurchase',
                        'SuppliesPurchase',
                        'ContainerWash',
                        'OtherFees',
                        'OilSurchargeFee',
                        'RegistrationFee',
                      ].includes(category)
                    "
                  >
                    <span
                      >{{
                        formatCurrencyString(
                          getPostingsTotalFromUnit([infocardPosting], "Pcs"),
                          1
                        )
                      }}
                    </span>
                    <span
                      v-if="
                        !['Charge', 'Income', 'WasteCollection'].includes(
                          category
                        )
                      "
                    >
                      {{ T("Web.Generic.Units.Pcs") }}</span
                    >
                    <span v-else>
                      <feather-icon
                        v-b-tooltip.hover.top="
                          T('Web.Generic.PostingCategories.WasteCollection')
                        "
                        icon="TruckIcon"
                    /></span>
                  </div>
                  <div
                    v-if="
                      [
                        'WasteCollection',
                        'WrongSorting',
                        'Income',
                        'Charge',
                        'DeliveryOrRepatriation',
                        'Exchange',
                      ].includes(category)
                    "
                  >
                    <span>{{
                      formatCurrencyString(
                        getPostingsTotalTonnage([infocardPosting]),
                        3
                      )
                    }}</span>
                    <span> Ton</span>
                  </div>

                  <div
                    v-if="
                      [
                        'WasteCollection',
                        'WrongSorting',
                        'Income',
                        'Charge',
                        'FutileDriving',
                        'DeliveryOrRepatriation',
                        'Exchange',
                      ].includes(category)
                    "
                  >
                    <span>{{
                      formatCurrencyString(
                        getPostingsTotalFromUnit([infocardPosting], "Hours"),
                        1
                      )
                    }}</span>
                    <span> {{ T("Web.Generic.Units.Hours") }}</span>
                  </div>
                  <div>
                    <span>{{
                      formatCurrencyString(
                        getTotalPostingsPrice([infocardPosting]),
                        2
                      )
                    }}</span>
                    <span> DKK</span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BFormSelect, VBTooltip } from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    postings: {
      required: false,
      default: () => [],
    },
    infocards: {
      required: false,
      default: () => [],
    },
    lines: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      groupeUnit: "INFOCARDS",
      activeCategory: "",
      activeInfocardId: null,
    };
  },
  computed: {
    postingsGroupedByType() {
      let list = this.postings.reduce((prev, curr) => {
        if (curr.postingCategory == undefined) return prev;
        if (prev[curr.postingCategory] == undefined) {
          prev[curr.postingCategory] = [];
        }
        curr.infocardName = this.getInfocardName(
          curr.postingDestinationInfoCardId
        );
        prev[curr.postingCategory].push(curr);
        return prev;
      }, {});

      Object.keys(list).forEach((key) => {
        list[key] = list[key].sort((a, b) =>
          a.infocardName.localeCompare(b.infocardName)
        );
      });
      return list;
    },
  },
  methods: {
    postingGroupedByInfocards(postings) {
      return postings.reduce((prev, curr) => {
        if (curr.postingDestinationInfoCardId == undefined) return prev;

        if (prev[curr.postingDestinationInfoCardId] == undefined) {
          prev[curr.postingDestinationInfoCardId] = [];
        }

        prev[curr.postingDestinationInfoCardId].push(curr);
        return prev;
      }, {});
    },

    getInfocardName(id) {
      return this.infocards.find((i) => i.id == id)?.name;
    },
    getInfocardGroupId(id) {
      return this.infocards.find((i) => i.id == id)?.internalGroupId;
    },
    getInvoiceLineText(id) {
      return this.lines.find((i) => i.id == id)?.text;
    },
    getSummedQtyForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingQuantity + curr.postingSecondaryQuantity;
        return prev;
      }, 0);
    },
    getSummedUnitPriceForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingUnitPrice;
        return prev;
      }, 0);
    },
    getSummedTotalPriceForPostingType(type) {
      return this.postingsGroupedByType[type].reduce((prev, curr) => {
        prev += curr.postingTotalPrice;
        return prev;
      }, 0);
    },
    formatCurrency(value, decimal = 2) {
      let floatValue = parseFloat(value);
      return floatValue.toFixed(decimal);
    },
    formatCurrencyString(value, decimal = 2) {
      var result = this.formatCurrency(value, decimal).replace(".", ",");
      return result == "NaN" ? "" : result;
    },
    getPostingsTotalFromUnit(postings, unit) {
      return postings.reduce((prev, curr) => {
        let toAdd = 0;
        if (curr.postingUnit == unit) {
          toAdd = curr.postingQuantity;
        } else if (curr.postingSecondaryUnit == unit) {
          toAdd = curr.postingSecondaryQuantity;
        }
        return prev + toAdd;
      }, 0);
    },
    getTotalPostingsPrice(postings) {
      return postings.reduce((prev, curr) => prev + curr.postingTotalPrice, 0);
    },
    handleCategoryClicked(category) {
      if (this.activeCategory == category) {
        this.activeCategory = "";
      } else {
        this.activeCategory = category;
      }
    },
    getPostingsTotalTonnage(postings) {
      return postings.reduce((prev, curr) => {
        let toAdd = 0;
        if (["Kg", "Tonnage", "Cubic"].includes(curr.postingUnit)) {
          toAdd =
            curr.postingUnit == "Kg"
              ? parseFloat(curr.postingQuantity) / 1000
              : parseFloat(curr.postingQuantity);
        } else if (
          ["Kg", "Tonnage", "Cubic"].includes(curr.postingSecondaryUnit)
        ) {
          toAdd =
            curr.postingSecondaryQuantity == "Kg"
              ? parseFloat(curr.postingSecondaryQuantity) / 1000
              : parseFloat(curr.postingSecondaryQuantity);
        }
        return prev + toAdd;
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-inner {
  height: calc(100% - 68px);
  overflow: auto;
  padding: 10px 20px;

  .posting-groupe {
    background-color: #f8f8f8;
    border: 1px solid #ebe9f1;
    margin-bottom: 10px;
    > div {
      &:first-child {
        font-size: 15px;
        padding: 10px;
        display: flex;
        align-items: center;
        > div {
          width: 65%;
          &:first-child {
            width: 25%;
          }
          &:nth-child(2) {
            text-align: center;
            display: flex;
            justify-content: space-between;
          }
          &:last-child {
            text-align: right;
            width: 10%;
          }
        }
      }
      &:nth-child(2) {
        border-top: 1px solid;
        padding: 10px;

        > div {
          > div {
            &:first-child {
              display: flex;
              align-items: center;
              > div {
                width: 65%;
                &:first-child {
                  width: 25%;
                }
                &:nth-child(2) {
                  text-align: center;
                  display: flex;
                  justify-content: space-between;
                }
                &:last-child {
                  text-align: right;
                  width: 10%;
                }
              }
            }
            &:last-child {
              margin: 10px 0px;
              background-color: #ebe9f1;
              > div {
                display: flex;
                > div {
                  font-size: 12px;
                  width: 65%;
                  &:first-child {
                    width: 25%;
                  }
                  &:nth-child(2) {
                    text-align: center;
                    display: flex;
                    justify-content: space-between;
                  }
                  &:last-child {
                    text-align: right;
                    width: 10%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &:hover {
      background-color: #ebe9f1;
      border-color: #ebe9f1;
    }
  }
}
</style>