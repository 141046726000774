<template>
  <b-card class="sticky bar">
    <create-location-modal
      ref="locationCreateModal"
      v-if="includeCreateLocation"
    />
    <create-or-update-infocard-modal ref="infoCardCreateModal" />
    <b-row>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" sm="6" v-if="locations.length > 0">
            <div class="d-flex align-items-center">
              <h6 class="text-muted mr-1">
                {{ T("Web.Generic.Locations.Locations", "Locations") }}
              </h6>
              <b-form-select
                :disabled="disabled"
                :placeholder="
                  T('Web.Generic.Locations.SelectLocation', 'Select Location')
                "
                v-model="content"
                label="name"
                :options="options"
                @input="handleInput"
                name="locationPicker"
              />
              <a
                v-if="clearable && value != undefined"
                class="ml-1"
                href="#"
                @click.prevent="clear"
                >x</a
              >
            </div>
          </b-col>
          <b-col sm="6" md="4" xl="3" cols="3" v-if="showCreateLocation">
            <b-button
              :disabled="disabled"
              variant="outline-primary"
              @click="openLocationCreateModal"
              class="w-100"
            >
              {{ T("Web.Generic.Locations.CreateLocation", "Create Location") }}
            </b-button>
          </b-col>
          <b-col sm="6" md="4" xl="3" cols="3" v-if="showCreateInfoCard">
            <b-button
              :disabled="disabled"
              v-if="locations.length > 0"
              variant="outline-primary"
              @click="openinfoCardCreateModal"
              class="w-100 CreateInfoCardBtn"
              @drop="(e) => $emit('info-card-create-btn-drop', e)"
              @dragenter.prevent
              @dragover.prevent
            >
              {{ T("Web.Generic.Infocards.CreateInfocard", "Create Infocard") }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <slot name="right"> </slot>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormSelect, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import CreateLocationModal from "@/components/modals/locations/CreateLocationModal.vue";
import { mapActions } from "vuex";
import CreateOrUpdateInfocardModal from "@/app/views/background-data/infocards/components/CreateOrUpdateInfocardModal.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    vSelect,
    CreateLocationModal,
    CreateOrUpdateInfocardModal,
  },
  props: {
    value: {
      required: true,
    },
    locations: {
      required: true,
    },
    includeCreateLocation: {
      default: false,
    },
    includeCreateInfoCard: {
      default: true,
    },
    clearable: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    options() {
      let list = this.locations.map((l) => ({ text: l.name, value: l }));
      list.unshift({
        text: this.T("Web.Generic.Choose", "Choose"),
        disabled: true,
        value: undefined,
      });
      return list;
    },
    showCreateLocation() {
      return this.includeCreateLocation && this.isAdmin();
    },
    showCreateInfoCard() {
      return this.includeCreateInfoCard && this.isAdmin();
    },
  },
  methods: {
    ...mapActions({
      realoadLocations: "locationsOld/reloadLocationsInfoCards",
    }),
    clear() {
      this.content = undefined;
      this.handleInput();
    },
    handleInput(e) {
      this.$emit("input", this.content);
    },
    async openinfoCardCreateModal() {
      const result = await this.$refs.infoCardCreateModal.openCreate(
        this.content
      );
      if (result) {
        // this.realoadLocations();
        this.$emit("infocardcreated", true);
      }
    },
    async openLocationCreateModal() {
      const result = await this.$refs.locationCreateModal.open();
      if (result) {
        await this.realoadLocations();
        this.content = this.locations.find((l) => l.id == result.id);
        this.handleInput();
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.bar {
  position: sticky;
  top: 100px;
  width: 100%;
  z-index: 1;
}
@media (max-width: 648px) {
  .row {
    flex-direction: column;
  }
  .col-3 {
    max-width: unset;
    margin: 5px 0;
  }
  .CreateInfoCardBtn {
    display: none;
  }
}
</style>