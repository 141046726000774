<template>
  <dragablemodal
    ref="showInfoCardPostingHistoryModal"
    :height="'auto'"
    :width="900"
    name="show-info-card-posting-history-modal"
  >
    <header v-if="infocard != null">
      {{
        T(
          "Web.Generic.Invoices.Reconciliations.InfocardPostingHistoryModal.Title",
          "Laste postings on"
        )
      }}: {{ infocard.name }}
    </header>
    <div class="modals-content">
      <transition mode="in-out" name="fade">
        <div class="d-flex justify-content-center" v-if="fetching">
          <b-spinner />
        </div>
        <div v-else>
          <reconcilation-postings-list
            v-if="postings && postings.length > 0"
            :postings="postings"
            :disabled="true"
            :highlightedLineId="null"
          />
          <h1 v-else>{{ T("Web.Generic.Invoices.Reconciliations.InfocardPostingHistoryModal.NoPostings", "No postings to show") }}</h1>
        </div>
      </transition>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button variant="light" class="float-right mr-2" @click="close">
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>
<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import { BButton, BSpinner } from "bootstrap-vue";
import ReconciliationService from "@/services/economics/reconciliation.service";
import ReconcilationPostingsList from "../locationsList/ReconcilationPostingsList.vue";
export default {
  components: {
    dragablemodal,
    BButton,
    BSpinner,
    ReconcilationPostingsList,
  },
  data() {
    return {
      infocard: null,
      fetching: false,
      postings: [],
    };
  },
  methods: {
    async open(infocard) {
      this.fetching = true;
      this.infocard = infocard;
      const service = new ReconciliationService();
      const result = await service.getPostingHistoryFromInfoCardId(infocard.id);
      this.postings = result;
      this.fetching = false;

      this.$refs.showInfoCardPostingHistoryModal.show();
    },
    close() {
      this.$refs.showInfoCardPostingHistoryModal.hide();
    },
  },
};
</script>