<template>
  <div>
    <create-collector-modal ref="collectorCreateModal" />
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      taggable
      :placeholder="T('Web.Generic.Infocards.ChooseOrCreateCollector', 'Choose or create collector')"
      :options="collectors"
      label="fullName"
      @option:created="handleCollectorCreation"
      v-model="content"
      :reduce="(collector) => collector.id"
      @option:selected="handleInput"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import CreateCollectorModal from "@/components/modals/infoCards/CreateCollectorModal.vue";

export default {
  props: {
    value: { required: true },
    selectedId: { required: false },
  },
  components: {
    vSelect,
    CreateCollectorModal,
  },
  created() {},
  data() {
    return {
      content: this.value,
    };
  },
  mounted() {
    this.fetchCollectors();
    if(this.content == '00000000-0000-0000-0000-000000000000') this.content = null;
  },
  computed: {
    ...mapGetters({ collectors: "collectors/list" }),
  },
  methods: {
    ...mapActions({ fetchCollectors: "collectors/fetchList" }),
    handleInput() {
      this.$emit("input", this.content);
    },
    async handleCollectorCreation(collector) {
      if (typeof collector === "object") collector = collector.fullName;
      const result = await this.$refs.collectorCreateModal.open(collector);
      if (!result) return (this.content = null && this.handleInput());

      this.content = this.collectors.find((c) => c.id == result.id);
      this.handleInput();
    },
    setContent(value) {
      this.content = value;
    },
  },
  watch: {
    collectors(newValue, oldValue) {
      if (this.selectedId == undefined) return;
      let elm = this.collectors.find((e) => e.id == this.selectedId);
      if (elm == undefined) return;

      this.setContent(elm);
    },
    content(newValue, oldValue) {
      if (newValue != oldValue) this.handleInput();
    },
  },
};
</script>