<template>
  <b-container>
    <b-card no-body class="mb-2">
      <div class="p-2 d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodFrom", "Period from")
            }}</label>
            <b-form-datepicker v-model="fromDate" placeholder="YYYY-MM-DD" />
          </div>
          <div>
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodTo", "Period to")
            }}</label>
            <b-form-datepicker v-model="toDate" />
          </div>
        </div>
        <div>
          <b-button @click="exportData" variant="flat-secondary" class="mr-1"
            >{{T("Web.Generic.Export")}} <feather-icon icon="DownloadIcon"
          /></b-button>
          <b-button @click="fetchReport"
            >{{ T("Web.Generic.Update", "Update") }}
            <feather-icon icon="RefreshCwIcon"
          /></b-button>
        </div>
      </div>
      <page-loader :loading="loading">
        <div class="posting-cat-con p-2" v-if="postingEntries != null">
          <div v-if="postingEntries.length == 0" class="text-center">
            <h3>{{ T("Web.Generic.NoDataToShow", "No data to show") }}</h3>
          </div>
          <posting-category-item
            v-for="(entries, category) in entriesGroupedByCategory"
            :key="category"
            :entries="entries"
            :category="category"
            :fromDate="fromDate"
            :toDate="toDate"
          />
        </div>
      </page-loader>
    </b-card>
  </b-container>
</template>

<script>
import * as XLSX from "xlsx";
import {
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BTr,
  BTd,
  BCard,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BContainer,
  BButton,
} from "bootstrap-vue";
import PostingCategoryItem from "./components/PostingCategoryItem.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ReportsService from "@/services/economics/reports.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BFormSelect,
    BTableSimple,
    BContainer,
    BButton,
    BThead,
    BTbody,
    BCard,
    BTh,
    BTd,
    BTr,
    PostingCategoryItem,
    PageLoader,
  },
  data() {
    return {
      loading: true,
      postingEntries: [],

      reportData: null,
      fromDate: this.getDefaultFromDateValue(),
      toDate: this.getDefaultToDateValue(),
    };
  },
  async created() {
    await this.fetchEntries();
  },
  computed: {
    ...mapGetters({ currentLocation: "appUser/getCurrentLocationScope" }),
    entriesGroupedByCategory() {
      return this.postingEntries.reduce((groups, item) => {
        const { postingCategory } = item;
        if (!groups[postingCategory]) {
          groups[postingCategory] = [];
        }
        groups[postingCategory].push(item);
        return groups;
      }, {});
    },
  },
  methods: {
    async fetchEntries() {
      this.loading = true;
      const service = new ReportsService();
      const { data } = await service.getPostingEntries(
        this.fromDate,
        this.toDate,
        this.currentLocation ? this.currentLocation.id : null
      );
      this.postingEntries = data.data;
      this.loading = false;
    },

    async fetchReport() {
      this.loading = true;
      const service = new ReportsService();
      var result = await service.getPostingsReportForLocation(
        this.fromDate,
        this.toDate,
        this.currentLocation ? this.currentLocation.id : null
      );
      this.reportData = result.data.data;
      this.loading = false;
    },
    getDefaultFromDateValue() {
      var localStorageValue = localStorage.getItem(
        "Rz_PostingReportingFromDateValue"
      );
      return localStorageValue
        ? localStorageValue
        : moment(new Date()).subtract("year", 1).format("YYYY-MM-DD");
    },
    getDefaultToDateValue() {
      var localStorageValue = localStorage.getItem(
        "Rz_PostingReportingToDateValue"
      );
      return localStorageValue
        ? localStorageValue
        : moment(new Date()).format("YYYY-MM-DD");
    },
    to3Decimal(amount) {
      if (!amount || amount == null || amount == undefined || amount == "")
        return 0;
      const options = {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      };
      return amount.toLocaleString("da-DK", options);
    },
    exportData() {
      const fileName = `Posteringer_${this.fromDate}_${this.toDate}.xlsx`;
      const self = this;
      let modifiedData = this.postingEntries.map((item) => {
        return {
          Dato: this.format_date(item.entryDate),
          Faktura: item.invoiceNumber,
          Posteringskategori: item.postingCategory,
          "Infokort kaldenavn": item.infocardName,
          "Infokort ref": item.infocardRefId,
          Affaldskategori: self.T(
            `Web.Generic.WasteCategories.${item.wasteCategory}`
          ),
          Affaldsfraktion: item.wasteFraction,
          Antal: self.to3Decimal(item.quantity),
          Enhed: self.T(`Web.Generic.Units.${item.unit}`),
          Enhedspris: self.to3Decimal(item.unitPrice),
          "Total pris": self.to3Decimal(item.totalPrice),
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },
  },
  watch: {
    currentLocation(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchEntries();
      this.loading = false;
    },
    fromDate(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchEntries();
      this.loading = false;
      localStorage.setItem("Rz_PostingReportingFromDateValue", newValue);
    },
    toDate(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.loading = true;
      this.fetchEntries();
      this.loading = false;
      localStorage.setItem("Rz_PostingReportingToDateValue", newValue);
    },
  },
};
</script>
