<template>
  <div
    class="info-card-con"
    @dragover.prevent="hoverActive = true"
    @dragleave.prevent="hoverActive = false"
    @drop="handleLineDropped"
    @click="collapsed = !collapsed"
  >
    <div
      class="head d-flex justify-content-between align-items-center"
      :class="{ hovering: hoverActive }"
    >
      <span>
        <b-badge class="mr-1" variant="secondary">{{
          postings.length
        }}</b-badge>
        {{ infocard.name }}</span
      >
      <small>
        {{ infocard.refId }}
        <feather-icon
          icon="InfoIcon"
          class="ml-1 info-icon"
          v-b-tooltip.hover.right="
            T('Web.Generic.Invoices.Reconciliations.ClickToSeeLastPostingsTo', 'Click to see last postings to ') + '\'' + infocard.name + '\''
          "
          @click="$emit('show-posting-history', infocard)"
        />
        <feather-icon
          class="ml-1 font-weight-bold collaps-icon"
          @click="collapsedLocked = !collapsedLocked"
          :icon="collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          v-b-tooltip.hover.top="'Kollaps liste'"
        />
      </small>
    </div>
    <div class="posting-list" v-show="!collapsed">
      <flex-table class="table" :showHeader="false">
        <template #body>
          <div class="postings-row">
            <div
              class="inner"
              v-for="(postings, category) in postingsOrderdByPostingType"
              :key="category"
            >
              <label>{{ T("Web.Generic.PostingCategories." + category) }}</label>
              <div id="posting-container" class="posting-list">
                <div class="posting-list-head-row">
                  <div>
                    {{ T('Web.Generic.Date') }}
                  </div>
                  <div>{{ T('Web.Generic.Description') }}</div>
                  <div>{{T('Web.Generic.Invoices.LineAmount') }}</div>
                  <div>{{ T('Web.Generic.Invoices.LineUnit') }}</div>
                  <div>{{ T('Web.Generic.Invoices.LineUnitPrice')}}</div>
                  <div>{{ T('Web.Generic.Invoices.LineTotal') }}</div>
                  <div></div>
                </div>
                <div class="posting-list-total-row">
                  <div>{{T('Web.Generic.Total', 'Total')}}</div>
                  <div></div>
                  <div>{{ getCategoryQtyTotal(category) | toCurrency }}</div>
                  <div></div>
                  <div>
                    {{ getCategoryUnitPriceTotal(category) | toCurrency }}
                  </div>
                  <div>
                    {{ getCategoryTotalPriceTotal(category) | toCurrency }}
                  </div>
                  <div></div>
                </div>
                <posting-list-item
                  :highlighted-line-id="highlightedLineId"
                  v-for="posting in postings"
                  :key="posting.id"
                  :posting="posting"
                  @posting-clicked="
                    (posting) => $emit('posting-clicked', posting)
                  "
                  @remove-posting-clicked="
                    (posting) => $emit('remove-posting-clicked', posting)
                  "
                  @scroll-to-line="(event) => $emit('scroll-to-line', event)"
                  :disabled="disabled"
                />
               <!--   @highlight-line="(event) => $emit('highlight-line', event)"-->
              </div>
            </div>
          </div>
        </template>
      </flex-table>
    </div>
  </div>
</template>

<script>
import FlexTable from "@/components/flex-table/FlexTable.vue";
import PostingListItem from "./PostingListItem.vue";
import { VBTooltip, BBadge } from "bootstrap-vue";
export default {
  components: { FlexTable, PostingListItem, BBadge },
  props: {
    infocard: {
      required: true,
    },
    postings: {
      required: false,
      default: [],
    },
    highlightedLineId: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      hoverActive: false,
      collapsed: true,
      collapsedLocked: false,
    };
  },
  computed: {
    postingsOrderdByPostingType() {
      return this.postings.reduce((a, b) => {
        (a[b.postingCategory] = a[b.postingCategory] || []).push(b);
        return a;
      }, {});
    },
  },
  methods: {
    tryCollapse()
    {
      if(this.collapsedLocked) return;
      this.collapsed = true;
    },
    handleLineDropped(event) {
      this.$emit("line-dropped-to-destination", {
        lineId: event.dataTransfer.getData("lineId"),
        accountId: this.infocard.id,
      });
      this.hoverActive = false;
    },
    getCategoryQtyTotal(category) {
      return this.postingsOrderdByPostingType[category].reduce(
        (prev, curr) => (prev += curr.postingQuantity),
        0
      );
    },
    getCategoryUnitPriceTotal(category) {
      return this.postingsOrderdByPostingType[category].reduce(
        (prev, curr) => (prev += curr.postingUnitPrice),
        0
      );
    },
    getCategoryTotalPriceTotal(category) {
      return this.postingsOrderdByPostingType[category].reduce(
        (prev, curr) => (prev += curr.postingTotalPrice),
        0
      );
    },
  },
  watch:{
    highlightedLineId(id)
    {
      /*
      if((id == null || this.postings.find(p => p.postingSubjectLineId == id) == undefined) && !this.collapsedLocked)
      {
        this.collapsed = true;
        return
      }else{
        this.collapsed = false;
      }

      */
    }
  }
};
</script>
<style lang="scss" scoped>
.posting-list-total-row {
  padding: 2px 10px;
  border-bottom: 1px solid #dfdfdf66;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 10px;
  font-weight: normal;
  background: #f4f4fb;
  div {
    &:nth-child(2) {
      width: 20%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    width: 15%;
    justify-content: space-between;
  }
}
.posting-list-head-row {
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  background: #f4f4fb;
  div {
    &:nth-child(2) {
      width: 20%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    width: 15%;
    justify-content: space-between;
  }
}

.info-card-con {
  margin-bottom: 5px;

  .head {
    color: #6e6b7b;
    background: #cadac8;
    border-radius: 4px;
    padding: 20px;
    font-weight: bold;
    &.with-postings {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    span {
      font-size: 18px;
    }
    &.hovering {
      background: #407e3d;
      color: #fff;
    }
  }
  .posting-list {
    border: 2px solid #ebe9f1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0px;
  }
  .info-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .postings-row {
    .inner {
      margin: 10px;
    }
    label {
      text-transform: uppercase;
      border-bottom: 2px solid #cadac8;
      display: block;
      padding: 2px;
      padding-left: 7px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  .collaps-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>