<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mt-2 mb-2"
      v-if="loading || !invoice"
    >
      <b-spinner />
    </div>
    <div id="invoice-container" v-else>
      <b-card no-body class="invoice-preview-card">
        <b-card-body class="invoice-padding invoice-head pb-0">
          <div
            class="
              d-flex
              justify-content-between
              flex-md-row flex-column
              invoice-spacing
              mt-0
            "
          >
            <div>
              <p class="font-weight-bold">
                {{ $t("InvoicePreviewCol.Reciver.Title") }}
              </p>
              <p class="card-text mb-25">{{ invoice.customer.name }}</p>
              <p class="card-text mb-25">
                {{ invoice.customer.address }}
              </p>
              <p class="card-text mb-25">
                {{ invoice.customer.zip }} {{ invoice.customer.city }}
              </p>
              <p class="card-text mb-25">{{ invoice.customer.cvr }}</p>
            </div>
            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 class="invoice-title">
                {{ getDocumentTypeDisplayText(invoice.documentType) }}
                <span class="invoice-number">#{{ invoice.invoiceNumber }}</span>
              </h4>
              <div>
                <p class="font-weight-bold">
                  {{ $t("InvoicePreviewCol.Sender.Title") }}
                </p>
                <p class="card-text mb-25">{{ invoice.supplier.name }}</p>
                <p class="card-text mb-25">
                  {{ invoice.supplier.address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.supplier.zip }}
                  {{ invoice.supplier.city }}
                </p>
                <p class="card-text">{{ invoice.supplier.cvr }}</p>
                <br />
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t("Generic.Date") }}
                  </p>
                  <p class="invoice-date">
                    {{ format_date(invoice.issueDate) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
        <flex-table>
          <template #header>
            <span> {{ $t("Generic.Date") }}</span>
            <span>{{ $t("Generic.Description") }}</span>
            <span>{{ $t("Generic.Amount") }}</span>
            <span>{{ $t("Generic.Unit") }}</span>
            <span>{{ $t("Generic.UnitPrice") }}</span>
            <span>{{ $t("Generic.Total") }}</span>
            <span class="action"></span>
          </template>
          <template #body>
            <div
              :id="'line|' + line.id"
              class="flex-row"
              :draggable="true"
            >
            </div>
          </template>
        </flex-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BSpinner, BCardBody } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    invoice: {
      required: true,
    },
  },
  components: {
    BCard,
    BSpinner,
    BCardBody,
    FlexTable,
  },
  computed: {
    ...mapGetters({
      loading: "economicsInvoices/isLoading",
      locations: "locationsOld/list",
    }),
  },
  methods: {
    getDocumentTypeDisplayText(type) {
      return this.$t(`Invoices.DocumentTypes.${type}`);
    },
  },
};
</script>
<style>
.flex-table .header {
  padding: 10px 0 10px 10px;
}
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";

.flex-table {
  .header {
    color: #fff;
    background-color: $primary !important;
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-head {
  background-color: #cadac8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.groups {
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-radius: 0.428rem;

  .item-center {
    padding: 10px;

    h4 {
      margin: 0;
    }
  }
}

.flex-table span {
  padding: unset;
  border: unset;
}

.flex-table .flex-row {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #ccc;
}

.line-posted {
  background-color: #cadac8 !important;
}

.line-highligted {
  background-color: #ffe083 !important;
}

.action {
  padding-top: 5px !important;
  width: 5% !important;
  justify-content: center !important;
}

body {
  &.dark-layout {
    .card-body {
      background-color: #2c8d5f;
    }

    .line-posted {
      background-color: #2c8d5f !important;
    }

    .line-highligted {
      background-color: #89722e !important;
    }
  }
}

.invoice-preview-card {
  margin-bottom: unset;
}
</style>
