var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('show-info-card-posting-history-modal',{ref:"showInfoCardPostingHistoryModal"}),(_vm.infocards && _vm.infocards.length == 0)?_c('div',[_c('h3',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.$t("Generic.NoInfocardsOnLocation"))+" ")]),_c('h6',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.$t("Generic.ReconciliationCreateInfocard"))+" ")])]):_vm._e(),(_vm.infocards && _vm.containerInfoCards.length > 0)?_c('b-card',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"BoxIcon","size":"25","stroke-width":"1"}}),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Generic.CONTAINERSFRACTIONS")))])],1),_c('hr'),_vm._l((_vm.containerInfoCards),function(infocard){return _c('div',{key:infocard.id,staticClass:"info-card-con",on:{"drop":function (event) { return _vm.handleLineToAccountDrop(event, infocard.id); },"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"head d-flex justify-content-between align-items-center",class:{
          hovering: _vm.preview.indexOf(infocard.id) > -1,
          'with-postings': _vm.getPostingsForAccount(infocard.id).length > 0,
        },on:{"dragover":function($event){return _vm.toggleOnDrag(infocard.id)},"dragleave":function($event){return _vm.collapseIfNotPinned(infocard.id)}}},[_c('span',[_vm._v(_vm._s(infocard.name))]),_c('small',[_vm._v(_vm._s(infocard.refId)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
              _vm.$t('Generic.LastPostingsText') + '\'' + infocard.name + '\''
            ),expression:"\n              $t('Generic.LastPostingsText') + '\\'' + infocard.name + '\\''\n            ",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.openShowInfoCardPostingHistoryModal(infocard)}}})],1)]),(_vm.getPostingsForAccount(infocard.id).length > 0)?_c('div',{staticClass:"posting-list"},[_c('flex-table',{staticClass:"table",attrs:{"showHeader":false},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"postings-row"},[(_vm.postings)?_c('reconcilation-postings-list',{attrs:{"postings":_vm.getPostingsForAccount(infocard.id),"highlighted-line-id":_vm.highlightedLineId,"disabled":_vm.disabled},on:{"posting-hover-enter":function (posting) { return _vm.$emit('posting-hover-enter', posting); },"posting-hover-leave":function($event){return _vm.$emit('posting-hover-leave', null)},"remove-posting-clicked":function (event) { return _vm.$emit('remove-posting-clicked', event); },"posting-clicked":function (posting) { return _vm.$emit('posting-clicked', posting); },"posting-scroll-event":function (lineId) { return _vm.$emit('posting-scroll-event', lineId); }}}):_vm._e()],1)]},proxy:true}],null,true)})],1):_vm._e()])})],2):_vm._e(),(_vm.accountInfoCards.length > 0)?_c('b-card',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ClipboardIcon","size":"25","stroke-width":"1"}}),_c('strong',{staticClass:"textupper"},[_vm._v(_vm._s(_vm.$t("Generic.Accounts")))])],1),_c('hr'),_vm._l((_vm.accountInfoCards),function(infocard){return _c('div',{key:infocard.id,staticClass:"info-card-con",on:{"drop":function (event) { return _vm.handleLineToAccountDrop(event, infocard.id); },"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"head d-flex justify-content-between align-items-center",class:{
          hovering: _vm.preview.indexOf(infocard.id) > -1,
          'with-postings': _vm.getPostingsForAccount(infocard.id).length > 0,
        },on:{"dragover":function($event){return _vm.toggleOnDrag(infocard.id)},"dragleave":function($event){return _vm.collapseIfNotPinned(infocard.id)}}},[_c('span',[_vm._v(_vm._s(infocard.name))]),_c('small',[_vm._v(_vm._s(infocard.refId)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
              _vm.$t('Generic.LastPostingsText') + '\'' + infocard.name + '\''
            ),expression:"\n              $t('Generic.LastPostingsText') + '\\'' + infocard.name + '\\''\n            ",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1",attrs:{"icon":"InfoIcon"},on:{"click":function($event){return _vm.openShowInfoCardPostingHistoryModal(infocard)}}})],1)]),(_vm.getPostingsForAccount(infocard.id).length > 0)?_c('div',{staticClass:"posting-list"},[_c('flex-table',{staticClass:"table",attrs:{"showHeader":false},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"postings-row"},[(_vm.postings)?_c('reconcilation-postings-list',{attrs:{"postings":_vm.getPostingsForAccount(infocard.id),"highlighted-line-id":_vm.highlightedLineId,"disabled":_vm.disabled},on:{"posting-hover-enter":function (posting) { return _vm.$emit('posting-hover-enter', posting); },"posting-hover-leave":function($event){return _vm.$emit('posting-hover-leave', null)},"remove-posting-clicked":function (event) { return _vm.$emit('remove-posting-clicked', event); },"posting-clicked":function (posting) { return _vm.$emit('posting-clicked', posting); }}}):_vm._e()],1)]},proxy:true}],null,true)})],1):_vm._e()])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }