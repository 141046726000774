<template>
  <section class="reconciliation-wrapper">
    <confirm-modal ref="confirmModal" />
    <create-or-update-infocard-modal
      ref-name="localInfoCardCreateModal"
      ref="localInfoCardCreateModal"
    />
    <content-modal
      id="postingOverviewModal"
      body-class="p-0"
      size="lg"
      :title="T('Web.Generic.Confirm', 'Confirm')"
    >
      <posting-overview-modal-inner
        :postings="postings"
        :infocards="infocards"
        :lines="invoice.invoiceLines"
        v-if="infocards && invoice"
      />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            v-if="isNotClosed"
            class="float-right dark-btn"
            @click="handleSaveAndClose"
            :disabled="submitting"
          >
            {{
              T(
                "Web.Generic.Invoices.Reconciliations.SaveAndFinish",
                "Save and finish"
              )
            }}</b-button
          >
          <b-button
            variant="secondary"
            class="float-right mr-1"
            @click="$bvModal.hide('postingOverviewModal')"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
        </div>
      </template>
    </content-modal>

    <transition name="fade" mode="out-in">
      <!-- Loading -->
      <div class="d-flex justify-content-center mt-2 mb-2" v-if="isLoading">
        <b-spinner />
      </div>
      <div v-else>
        <location-picker-bar
          v-if="selectedLocation != undefined"
          :locations="locations"
          :includeCreateLocation="isNotClosed"
          :includeCreateInfoCard="isNotClosed"
          :showConcernLocations="true"
          v-model="selectedLocation"
          @new-location-created="handleNewLocationCreated"
          @info-card-create-btn-drop="handleCreateInfoCardBtnDrop"
          :disabled="
            !reconciliation ||
            reconciliation.locationScopeId == null ||
            !reconciliation.locationConfirmed
          "
        >
          <template #right>
            <div class="h-100 d-flex align-items-center float-left">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                class="float-left"
                v-model="invoice.needsConfirmation"
                @change="updateInvoiceNeedsConfirmation"
              >
                {{
                  T(
                    "Web.Generic.Invoices.Reconciliations.MarkForConfirmation",
                    "Mark invoice for checking"
                  )
                }}
              </b-form-checkbox>
            </div>

            <b-button
              v-if="isNotClosed"
              class="float-right dark-btn"
              @click="$bvModal.show('postingOverviewModal')"
            >
              {{
                T(
                  "Web.Generic.Invoices.Reconciliations.SaveAndFinish",
                  "Save and finish"
                )
              }}</b-button
            >
            <b-button
              v-else
              class="float-right dark-btn"
              @click="handleGoBack"
              >{{ T("Web.Generic.Back") }}</b-button
            >
            <b-button
              v-if="isNotClosed && !popupMode"
              class="float-right mr-1"
              :to="{ name: 'economics.invoices.list' }"
              @click="handleSaveClicked"
            >
              {{ T("Web.Generic.Save") }}
            </b-button>
            <b-button
              v-if="isNotClosed && popupMode"
              class="float-right mr-1"
              @click="handleSaveClicked"
            >
              {{ T("Web.Generic.Save") }}
            </b-button>
            <b-button
              v-if="isNotClosed"
              class="float-right mr-1"
              variant="outline-primary"
              @click.prevent="refreshSuggestions"
            >
              {{
                T(
                  "Web.Generic.Invoices.Reconciliations.RefreshSuggestions",
                  "Refresh system suggestions"
                )
              }}
            </b-button>
            <b-button
              class="float-right mr-1"
              @click="reopenReconciliation"
              v-else
            >
              {{ T("Web.Generic.Edit") }}</b-button
            >
            <div class="float-right h-100 d-flex mt-1">
              <b-form-checkbox
                switch
                class="mr-1 mb-0"
                @change="handleLayoutSwitchChanged"
                v-model="newLayoutMode"
              >
                {{
                  T(
                    "Web.Generic.Invoices.Reconciliations.UseNewLayout",
                    "Brug nyt layout"
                  )
                }}
              </b-form-checkbox>
            </div>
          </template>
        </location-picker-bar>
        <b-overlay :show="fetchingData" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <b-spinner />
              <p class="mt-1">
                {{
                  T(
                    "Web.Generic.Invoices.Reconciliations.GeneratingSystemSuggestions",
                    "Generating system suggestions..."
                  )
                }}
              </p>
            </div>
          </template>

          <b-row ref="postingView">
            <b-col
              cols="5"
              xl="5"
              class="scroll-col"
              style="border-right: 2px solid #ccc"
            >
              <div class="invoice-con">
                <div v-if="locations.length == 0">
                  <h3 class="text-center text-muted">
                    {{ T("Web.Generic.Locations.NoLocations", "No locations") }}
                  </h3>
                  <h6 class="text-center text-muted">
                    {{
                      T(
                        "Web.Generic.Invoices.Reconciliations.ReconciliationCreateLocation",
                        "Create a new location to continue"
                      )
                    }}
                  </h6>
                </div>
                <div
                  v-if="
                    !reconciliation ||
                    reconciliation.locationScopeId == null ||
                    !reconciliation.locationConfirmed
                  "
                >
                  <b-card>
                    <h3>
                      {{
                        T(
                          "Web.Generic.Invoices.Reconciliations.ConfirmLocationToContinue",
                          "Please confirm location to continue"
                        )
                      }}
                    </h3>
                    <div>
                      <b-form-select
                        :placeholder="T('Web.Generic.Locations.SelectLocation')"
                        label="name"
                        :options="locationOptions"
                        v-model="locationScopeId"
                        name="locationPicker"
                      />
                      <b-button
                        variant="primary"
                        class="mt-1"
                        @click="confirmLocationScope"
                        >{{
                          T(
                            "Web.Generic.Invoices.Reconciliations.ConfirmLocation",
                            "Confirm location"
                          )
                        }}</b-button
                      >
                    </div>
                  </b-card>
                </div>
                <posting-destinations-panel
                  v-else
                  @posting-clicked="handlePostingClick"
                  @remove-posting-clicked="handleRemovePosting"
                  @line-dropped-to-destination="
                    handleLineDraggedToInfoCardEvent
                  "
                  @highlight-line="handleHighlightLine"
                  @scroll-to-line="handleScrollToLine"
                  :highlighted-line-id="highlightedLineId"
                  :locationScopeId="selectedLocation.id"
                  :postings="postings"
                  :disabled="!isNotClosed"
                />
              </div>
            </b-col>
            <b-col cols="7" xl="7" class="scroll-col">
              <invoice-preview-col
                :invoice="invoice"
                :postings="postings"
                :highlighted-line-id="highlightedLineId"
                @line-hover-enter="(lineId) => (highlightedLineId = lineId)"
                @line-hover-leave="highlightedLineId = null"
                @scroll-to-posting="handleScrollToPosting"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </div>
    </transition>
    <posting-edit-modal ref="postingEditModal" />
    <posting-split-modal ref="postingSplitModal" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BRow,
  BSpinner,
  BCol,
  BCard,
  BButton,
  BToast,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import ReconciliationService from "@/services/economics/reconciliation.service";
import InvoicePreviewCol from "./components/InvoicePreviewCol.vue";
import PostingEditModal from "./components/PostingEditModal.vue";
import PostingSplitModal from "./components/PostingSplitModal.vue";
import LocationReconciliationList from "./components/locationsList/LocationReconciliationList.vue";
import LoactionReconcilationCard from "./components/locationsList/LoactionReconcilationCard.vue";
import LocationPickerBar from "@/components/LocationPickerBar.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import InfoCardCreateModal from "@/components/modals/infoCards/CreateInfoCardModal.vue";
import CreateOrUpdateInfocardModal from "@/app/views/background-data/infocards/components/CreateOrUpdateInfocardModal.vue";
import InvoicePanel from "./components/InvoicePanel.vue";
import PostingDestinationsPanel from "./components/posting-destinations-panel/PostingDestinationsPanel.vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import PostingOverviewModalInner from "./components/PostingOverviewModalInner.vue";
export default {
  components: {
    BSpinner,
    BToast,
    BRow,
    BCol,
    BCard,
    BButton,
    ReconciliationService,
    InvoicePreviewCol,
    PostingEditModal,
    PostingSplitModal,
    LocationReconciliationList,
    LoactionReconcilationCard,
    LocationPickerBar,
    ConfirmModal,
    InfoCardCreateModal,
    BOverlay,
    CreateOrUpdateInfocardModal,
    BFormSelect,
    InvoicePanel,
    PostingDestinationsPanel,
    BFormCheckbox,
    ContentModal,
    PostingOverviewModalInner,
  },
  props: {
    invoiceIdOverwrite: {
      required: false,
      default: null,
    },
    onSaveAction: {
      required: false,
      default: null,
    },
    goBackAction: {
      required: false,
      default: null,
    },
    popupMode: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      scrolling: false,
      fetchingData: false,
      savingData: false,
      locationScopeId: null,

      activeLocation: undefined,
      selectedLocation: null,
      highlightedLineId: null,
      reconciliations: [],
      highlightedLine: null,
      submitting: false,
      reconciliationsService: null,
      cssProp: "312px",
      postingIsLoading: false,
    };
  },
  async created() {
    await this.createdInit();
  },
  computed: {
    ...mapGetters({
      isLoadingInvoice: "economicsInvoices/isLoading",
      isLoadingLocations: "locations/isLoading",
      isLoadingReconciliation: "economicsReconciliation/isLoading",
      getInvoice: "economicsInvoices/byId",
      getReconciliation: "economicsReconciliation/byId",
      locations: "locations/list",
      isLoadingInfocards: "infocards/isLoading",
      infocards: "infocards/list",
    }),
    newLayoutMode: {
      get() {
        return this.getUserSetting("reconciliation.layoutMode", false);
      },
      async set(value) {
        await this.setUserSettingAsync("reconciliation.layoutMode", value);
      },
    },
    isLoading() {
      return (
        this.isLoadingInvoice ||
        this.isLoadingLocations ||
        this.isLoadingInfocards ||
        this.isLoadingReconciliation
      );
    },
    invoiceId() {
      return this.invoiceIdOverwrite != null
        ? this.invoiceIdOverwrite
        : this.$route.params.invoiceId;
    },
    invoice() {
      return this.getInvoice(this.invoiceId);
    },
    reconciliation() {
      return this.getReconciliation(this.invoiceId);
    },

    postings() {
      return this.reconciliation
        ? this.reconciliation.postings.sort((a, b) =>
            a.postingCategory?.localeCompare(b.postingCategory)
          )
        : [];
    },

    invoiceLines() {
      return this.invoice.invoiceLines;
    },
    isNotClosed() {
      return this.reconciliation?.status == "Draft";
    },
    locationScopeIdCalc() {
      if (this.reconciliation && this.reconciliation.locationScopeId != null)
        return this.reconciliation.locationScopeId;
      else if (this.selectedLocation) return this.selectedLocation.id;
      else return null;
    },
    locationOptions() {
      let list = this.locations.map((l) => ({
        text: `${l.name} (${l.assetId})`,
        value: l.id,
      }));
      list.unshift({
        text: this.T("Web.Generic.Choose"),
        disabled: true,
        value: undefined,
      });
      return list;
    },
  },
  methods: {
    async handleCreateInfoCardBtnDrop(e) {
      const result = await this.$refs.localInfoCardCreateModal.openCreate(
        this.selectedLocation,
        e.dataTransfer.getData("lineText"),
        e.dataTransfer.getData("supplierContactId")
      );
      if (result) {
        this.realoadLocations();
        this.$emit("infocardcreated", true);
      }
    },
    ...mapActions({
      fetchInovice: "economicsInvoices/fetchSingle",
      updateInvoiceNeedsConfirmationAction:
        "economicsInvoices/updateInvoiceNeedsConfirmation",
      fetchLocations: "locations/fetchList",
      fetchLocationsOld: "locationsOld/fetchList",
      fetchReconciliation: "economicsReconciliation/fetchSingle",
      getInfoCardPostingDraft:
        "economicsReconciliation/getInfoCardPostingDraft",
      createInfoCardPosting: "economicsReconciliation/createInfoCardPosting",
      deletePosting: "economicsReconciliation/deletePosting",
      updatePosting: "economicsReconciliation/updateInfoCardPosting",
      realoadLocations: "locationsOld/reloadLocationsInfoCards",
      refreshReconciliation: "economicsReconciliation/refreshReconciliation",
      fetchLocationInfocards: "infocards/fetchList",
      reopenReconciliationAction: "invoiceImports/reopenReconciliation",
    }),

    async updateInvoiceNeedsConfirmation(e) {
      this.updateInvoiceNeedsConfirmationAction({
        id: this.invoice.id,
        value: e,
      });
    },

    async createdInit() {
      await this.fetchLocations();
      if (this.locations && this.locations.length > 0)
        this.selectedLocation = this.locations[0];

      await this.fetchInovice({
        id: this.invoiceId,
        include: ["invoiceLines"],
      });
      await this.fetchReconciliation({ id: this.invoiceId });

      this.locationScopeId = this.locationScopeIdCalc;

      this.reconciliationsService = new ReconciliationService();
      // Fetch nedded info

      this.cssProp =
        "calc(100vh - " +
        this.$refs.postingView.getBoundingClientRect().top +
        "px)";
      if (this.reconciliation.locationScopeId) {
        this.selectedLocation = this.locations.find(
          (l) => l.id == this.reconciliation.locationScopeId
        );
      }
    },
    handleSaveClicked() {
      this.$emit("soft-save-clicked");
    },
    handleGoBack() {
      if (this.goBackAction != null) {
        this.goBackAction();
      } else {
        this.$router.go(-1);
      }
    },
    async reopenReconciliation() {
      if (
        !(await this.$refs.confirmModal.confirm(
          "Du er ved at genåbne en afstemt faktura. Ønsker du at fortsætte?"
        ))
      )
        return;
      this.fetchingData = true;
      await this.reopenReconciliationAction(this.reconciliation.id);
      await this.createdInit();
      this.fetchingData = false;
    },
    handleHighlightLine(lineId) {
      this.highlightedLineId = lineId;
    },
    handleScrollToLine(lineId) {
      let elm = document.getElementById("line|" + lineId);
      if (elm != undefined) {
        elm.scrollIntoView();
      }
    },
    handleScrollToPosting(postingId) {
      let elm = document.getElementById("posting|" + postingId);
      if (elm != undefined) {
        elm.scrollIntoView();
      }
    },
    setSelectedLocation(event) {
      this.selectedLocation = event.location;
    },
    handleCreateLocationBtnDrop(event) {
      console.log(event);
    },
    handleNewLocationCreated(id) {
      this.selectedLocation = this.locations.find((l) => l.id == id);
    },
    handlePostingHoverEnter(posting) {
      this.highlightedLineId = posting.postingSubjectLineId;
    },
    async handleLineDraggedToInfoCardEvent(event) {
      const invoiceLine = this.invoiceLines.find(
        (item) => item.id === event.lineId
      );
      if (invoiceLine.unit == "ZZ") {
        if (
          !(await this.$refs.confirmModal.confirm(
            this.T(
              "Web.Generic.Invoices.Reconciliations.ReconciliationTextPostingConfirm",
              "You are about to post a line that seems to be a text line. Do you wish to continue?"
            )
          ))
        )
          return;
        let existingEmptyPosting = this.postings.find(
          (p) =>
            p.postingSubjectLineId == event.lineId && p.type == "EmptyPosting"
        );
        if (existingEmptyPosting) {
          await this.deletePosting({
            postingId: existingEmptyPosting.id,
            reconciliationId: this.invoiceId,
          });
        }
      }

      this.fetchingData = true;
      const postingDraftResult = await this.getInfoCardPostingDraft({
        reconciliationId: this.invoiceId,
        invoiceLineId: event.lineId,
        infoCardId: event.accountId,
      });
      this.fetchingData = false;
      var posting = postingDraftResult.postingDraft;

      var canCreatePosting = true;
      // Need the user to supply missing info
      if (postingDraftResult.needsInfo)
        canCreatePosting = await this.openEditPostingModal(
          posting,
          invoiceLine
        );
      if (!canCreatePosting) return;
      if (posting.isSplitPosting) {
        const existingLinePostings = this.postings.filter(
          (p) => p.postingSubjectLineId == posting.postingSubjectLineId
        );
        if (
          existingLinePostings.some(
            (p) => p.postingDestinationInfoCardId == event.accountId
          )
        ) {
          !(await this.$refs.confirmModal.confirm(
            this.T(
              "Web.Generic.Invoices.Reconciliations.ReconciliationTextPostingToSameInfoCard",
              "Cannot post a line to the same Infocard twice."
            )
          ));
          return;
        }

        var splittedPostings = await this.openSplitPostingModal(
          existingLinePostings.concat([posting]),
          invoiceLine
        );
        if (!splittedPostings) return;
        this.savingData = true;

        await this.createInfoCardPosting({
          reconciliationId: this.invoiceId,
          posting: posting,
        });

        splittedPostings.forEach((posting) =>
          this.updatePosting({
            posting: posting,
            reconciliationId: this.invoiceId,
          })
        );
        this.savingData = false;
      } else {
        this.savingData = true;

        await this.createInfoCardPosting({
          reconciliationId: this.invoiceId,
          posting: posting,
        });
        const linesWithSameDescription = this.invoice.invoiceLines.filter(
          (l) =>
            l.text == posting.postingDescription &&
            l.id != posting.postingSubjectLineId
        );

        var postingSecondaryQuantity = posting.postingSecondaryQuantity;
        var infoCard = this.selectedLocation.infoCards.find(
          (i) => i.id == event.accountId
        );

        if (infoCard.type == "AccountInfoCard") {
          for (const line of linesWithSameDescription) {
            if (!this.postings.some((p) => p.postingSubjectLineId == line.id)) {
              const postingDraftResult = await this.getInfoCardPostingDraft({
                reconciliationId: this.invoiceId,
                invoiceLineId: line.id,
                infoCardId: event.accountId,
              });
              postingDraftResult.postingDraft.postingSecondaryQuantity =
                postingSecondaryQuantity;
              var posting = postingDraftResult.postingDraft;
              if (!postingDraftResult.needsInfo) {
                await this.createInfoCardPosting({
                  reconciliationId: this.invoiceId,
                  posting: posting,
                });
              }
            }
          }
        }
      }
      this.savingData = false;
    },

    async handleRemovePosting(event) {
      let accountId = event.accountId;
      let invoiceLineId = event.invoiceLineId;
      const invoiceLine = this.invoiceLines.find(
        (item) => item.id === event.invoiceLineId
      );
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.Invoices.Reconciliations.ComfirmDeletePostingText",
            "Are you sure you want to delete the posting?"
          )
        ))
      )
        return;
      // Check if we need to update split before deleting
      const remainingLinePostings = this.postings.filter(
        (elm) => elm.postingSubjectLineId == invoiceLineId && elm.id != event.id
      );

      if (remainingLinePostings.length > 0) {
        var splittedPostings = await this.openSplitPostingModal(
          remainingLinePostings,
          invoiceLine
        );
        if (!splittedPostings) return;

        if (splittedPostings.length == 1) {
          splittedPostings[0].isSplitPosting = false;
          console.log(splittedPostings);
        }

        splittedPostings.forEach((posting) =>
          this.updatePosting({
            posting: posting,
            reconciliationId: this.invoiceId,
          })
        );
      }

      await this.deletePosting({
        postingId: event.id,
        reconciliationId: this.invoiceId,
      });
    },

    async handlePostingClick(posting) {
      if (!this.isNotClosed) return;
      let updatedPosting = { ...posting };
      if (!(await this.openEditPostingModal(updatedPosting))) return;
      this.postingIsLoading = true;
      await this.updatePosting({
        posting: updatedPosting,
        reconciliationId: this.invoiceId,
      });
      this.postingIsLoading = false;
    },

    invoiceLineIsPartOfPosting(invoiceLineId) {
      return this.postings.some((item) => item.invoiceLineId === invoiceLineId);
    },

    async openEditPostingModal(posting) {
      return await this.$refs.postingEditModal.open(posting);
    },

    async openSplitPostingModal(postings, invoiceLine) {
      return await this.$refs.postingSplitModal.open(postings, invoiceLine);
    },

    postSuggestionIsMissingInfo(postSuggestion) {
      const requiredAttributes = ["date", "description", "unit", "type"];
      return !requiredAttributes.every(
        (attribute) =>
          postSuggestion[attribute] && postSuggestion[attribute] != "Unknown"
      );
    },

    parseReconciliationItemFromInvoiceLine(line, accountId) {
      const item = {
        lineId: line.id,
        accountId: accountId,
        date: line.date,
        description: line.text,
        amount: line.amount,
        unit: line.unit,
        totalAmount: line.priceTotal,
      };
      return item;
    },

    async resolveMissingReconcilationInfo(reconciliationItem) {
      const requiredAttributes = ["date", "description"];
      if (
        requiredAttributes.every((attribute) => reconciliationItem[attribute])
      )
        return true;
      // We need to prompt for missing info
      const informationProvided = await this.$refs.reconciliationEditModal.open(
        reconciliationItem
      );
      return informationProvided;
    },

    async resolveSplitInfo(reconciliationItem, invoiceLine) {
      const existingLineReconciliations = this.reconciliations.filter(
        (item) => item.lineId == reconciliationItem.lineId
      );
      // Not a split
      if (!existingLineReconciliations.length > 0) return true;
      // Split line we need some info
      reconciliationItem.totalAmount = 0;
      reconciliationItem.amount = 0;
      const splitInfoProvided = await this.$refs.reconciliationSplitModal.open(
        existingLineReconciliations.concat([reconciliationItem]),
        invoiceLine
      );
      return splitInfoProvided;
    },

    async handleRemoveReconcilationClicked(event) {
      // Check if multiple reconcilations exists, so we need to apply split
      const remaningLineReconcilations = this.reconciliations.filter(
        (item) =>
          item.lineId === event.lineId && item.accountId !== event.accountId
      );
      if (remaningLineReconcilations.length > 0) {
        const splitInfoProvided =
          await this.$refs.reconciliationSplitModal.open(
            remaningLineReconcilations,
            this.invoiceLines.find((item) => item.id === event.lineId)
          );
        if (!splitInfoProvided) return;
      }
      // Remove
      const indexOfElement = this.reconciliations.indexOf(
        this.reconciliations.find(
          (item) =>
            item.accountId === event.accountId && item.lineId === event.lineId
        )
      );
      this.reconciliations.splice(indexOfElement, 1);
    },
    handleReconcilationActive(event) {
      this.highlightedLine = event.lineId;
    },

    handleReconcilationInActive() {
      this.highlightedLine = null;
    },

    handleIncoiceLineActive(lineId) {
      if (!this.reconciliations.find((elm) => elm.lineId === lineId)) return;
      this.highlightedLine = lineId;
    },

    async refreshSuggestions() {
      this.fetchingData = true;
      await this.refreshReconciliation(this.invoiceId);
      this.fetchingData = false;
    },

    handleIncoiceLineInActive() {
      this.highlightedLine = null;
    },
    async handleSaveAndClose() {
      try {
        const someLinesAreNotPosted = this.invoice.invoiceLines.some(
          (l) => !this.postings.some((p) => p.postingSubjectLineId == l.id)
        );

        if (
          someLinesAreNotPosted &&
          !(await this.$refs.confirmModal.confirm(
            this.T(
              "Web.Generic.Invoices.Reconciliations.ReconciliationMissingPostingConfirm",
              "Some lines are not posted. Do you want to continue? Note that this cannot be undone"
            )
          ))
        )
          return;
        this.submitting = true;

        await this.reconciliationsService.confirmReconciliation(
          this.reconciliation.id
        );

        if (this.onSaveAction != null) {
          this.onSaveAction();
        } else {
          this.$router.push({ name: "economics.invoices.list" });
        }
      } finally {
        this.submitting = false;
      }
    },
    async confirmLocationScope() {
      try {
        this.fetchingData = true;
        await this.reconciliationsService.setLocationScope(
          this.invoiceId,
          this.locationScopeId
        );
        let needsRefresh = false;
        needsRefresh = true;
        if (
          this.reconciliation.locationScopeId != this.locationScopeId &&
          this.reconciliation.status != "Confirmed"
        ) {
          needsRefresh = true;
        }
        this.reconciliation.locationScopeId = this.locationScopeId;
        this.reconciliation.locationConfirmed = true;
        this.selectedLocation = this.locations.find(
          (l) => l.id == this.locationScopeId
        );
        console.log(needsRefresh);
        if (needsRefresh) await this.refreshSuggestions();
      } finally {
        this.fetchingData = false;
      }
    },
  },
  watch: {
    async selectedLocation(newValue, oldValue) {
      await this.fetchLocationInfocards({
        params: { locationId: this.selectedLocation.id },
      });

      /*
      if(this.reconciliation && this.reconciliation.locationScopeId != this.setSelectedLocation.id)
      {
        console.log(this.reconcilation)
        console.log(newValue)
        console.log(oldValue)
        console.log("DIFF")
      }
      */
    },
    /*
    async postings(newValue, oldValue) {
      if (newValue == null || !this.isNotClosed) return;
      try {
        this.submitting = true;
        await this.reconciliationsService.saveReconciliationDraft(
          this.invoice.reconciliation.id,
          this.postings
        );
        this.$notify({
          group: "errors",
          title: this.$t("ReconciliationView.DraftSavedTitle"),
          text: this.$t("ReconciliationView.DraftSavedText"),
        });
      } catch (E) {
        this.$notify({
          group: "errors",
          title: this.$t("ReconciliationView.DraftSavedErrorTitle"),
          text: this.$t("ReconciliationView.DraftSavedErrorText"),
          type: "error",
        });
      } finally {
        this.submitting = false;
      }
    },
    */
  },
};
</script>

<style lang="scss" scoped>
.header-navbar {
  display: none;
}
.sticky {
  .card-body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.scroll-col {
  height: calc(100vh - 217px);
  overflow-y: auto;
  scroll-behavior: smooth;
}
.dark-btn {
  background-color: #000 !important;
  color: #fff;
}
</style>