<template>
  <div id="update-invoice-line-modal">
    <dragablemodal
      ref="UpdateInvoiceLineModal"
      :height="'auto'"
      :width="900"
      name="update-invoice-line-modal"
      :id="'update-invoice-line-modal'"
    >
      <header class="font-weight-bold">
        {{ T("Web.Reconciliations.EditInvoiceLine", "Edit Invoice line") }}
      </header>
      <div class="modals-content" style="overflow: visible">
        <page-loader :loading="isLoading">
          <div class="d-flex line-con" v-if="localLine != null">
            <b-form-group :label="T('Web.Generic.Invoices.LineDate', 'Date')">
              <b-form-datepicker
                v-model="localLine.date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"
                size="sm"
              />
            </b-form-group>
            <b-form-group
              :label="T('Web.Generic.Invoices.LineDescription', 'Description')"
            >
              <b-form-input
                type="text"
                size="sm"
                v-model="localLine.text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="T('Web.Generic.Invoices.LineAmount', 'Amount')"
            >
              <b-form-input
                type="number"
                size="sm"
                v-model="localLine.amount"
                @change="localLine.amount = formatCurrency(localLine.amount, 3)"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="T('Web.Generic.Invoices.LineUnit', 'Unit')">
              <b-form-input
                type="text"
                size="sm"
                v-model="localLine.unit"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="T('Web.Generic.Invoices.LineUnitPrice', 'Unit price')"
            >
              <b-form-input
                type="number"
                size="sm"
                v-model="localLine.priceAmount"
                @change="
                  localLine.priceAmount = formatCurrency(localLine.priceAmount)
                "
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="T('Web.Generic.Invoices.LineTotal', 'Total price')"
            >
              <b-form-input
                type="number"
                size="sm"
                v-model="localLine.priceTotal"
                @change="
                  localLine.priceTotal = formatCurrency(localLine.priceTotal)
                "
                lazy
              ></b-form-input>
            </b-form-group>
          </div>
        </page-loader>
      </div>
      <footer class="modal-footer">
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="executeUpdateLine"
          >
            {{ T("Web.Generic.Save") }}
          </b-button>
          <b-button variant="light" class="float-right mr-2" @click="close">
            {{ T("Web.Generic.Close") }}
          </b-button>
        </div>
      </footer>
    </dragablemodal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormDatepicker,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";
import ResourceService from "@/services/base/resource.service";
import PageLoader from "@/app/components/layout/PageLoader.vue";
export default {
  components: {
    dragablemodal,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BButton,
    PageLoader,
  },
  data() {
    return {
      localLine: null,
      line: null,
      invoiceId: null,
      isLoading: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    async open(invoiceId, line) {
      this.line = line;
      this.localLine = { ...line };
      this.invoiceId = invoiceId;
      this.localLine.amount = this.formatCurrency(this.localLine.amount, 3);
      this.localLine.priceAmount = this.formatCurrency(
        this.localLine.priceAmount
      );
      this.localLine.priceTotal = this.formatCurrency(
        this.localLine.priceTotal
      );
      this.$refs.UpdateInvoiceLineModal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    close() {
      this.$refs.UpdateInvoiceLineModal.hide();
      this.line = null;
      this.invoiceId = null;
      this.localLine = null;
    },
    formatCurrency(value, decimal = 2) {
      let floatValue = parseFloat(value);
      return (floatValue < 0.01) ? floatValue.toFixed(3) : floatValue.toFixed(2);
    },
    async executeUpdateLine() {
      try {
        this.isLoading = true;
        const invoiceService = new ResourceService(
          `economics/invoices/${this.invoiceId}/lines`
        );
        await invoiceService.update(this.localLine);
        this.line.data = this.localLine.date;
        this.line.text = this.localLine.text;
        this.line.amount = parseFloat(this.localLine.amount);
        this.line.priceAmount = parseFloat(this.localLine.priceAmount);
        this.line.priceTotal = parseFloat(this.localLine.priceTotal);
        this.close();
        return this.resolvePromise(localLine);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style >
#update-invoice-line-modal .vm--modal {
  overflow: visible !important;;
}
</style>

<style lang="scss" scoped>
.line-con {
  gap: 10px;

  fieldset {
    &:first-child {
      width: 150px;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      width: 70px;
    }
    &:nth-child(4) {
      width: 70px;
    }
    &:nth-child(6) {
      width: 150px;
    }
    &:nth-child(7) {
      width: 150px;
    }
  }
}
</style>