<template>
  <div class="wrap">
    <div class="d-flex justify-content-center mt-2 mb-2" v-if="isLoading">
      <b-spinner />
    </div>
    <div v-else>
      <show-info-card-posting-history-modal
        ref="showInfoCardPostingHistoryModal"
      />
      <div v-if="infocards && infocards.length == 0">
        <h3 class="text-center text-muted">
          {{
            T(
              "Web.Generic.Invoices.Reconciliations.NoInfocardsOnLocation",
              "No infocard on the location"
            )
          }}
        </h3>
        <h6 class="text-center text-muted">
          {{
            T(
              "Web.Generic.Invoices.Reconciliations.CreateInfocardToContinue",
              "Create a new infocard to continue"
            )
          }}
        </h6>
      </div>
      <b-card class="d-flex" v-if="infocards && containerInfoCards.length > 0">
        <div class="d-flex align-items-center">
          <feather-icon
            icon="BoxIcon"
            size="25"
            stroke-width="1"
            class="mr-1"
          />
          <strong> {{ T("Web.Generic.Infocards.Types.Container") }}s</strong>
        </div>
        <hr />
        <posting-destination-list-item
          v-for="infocard in containerInfoCards"
          :key="infocard.id"
          :infocard="infocard"
          :highlighted-line-id="highlightedLineId"
          @show-posting-history="openShowInfoCardPostingHistoryModal"
          :postings="getInfocardPostings(infocard)"
          @posting-clicked="(posting) => $emit('posting-clicked', posting)"
          @remove-posting-clicked="
            (posting) => $emit('remove-posting-clicked', posting)
          "
          @line-dropped-to-destination="
            (event) => $emit('line-dropped-to-destination', event)
          "
          @highlight-line="(event) => $emit('highlight-line', event)"
          @scroll-to-line="(event) => $emit('scroll-to-line', event)"
          :disabled="disabled"
        />
      </b-card>
      <b-card class="d-flex" v-if="accountInfoCards.length > 0">
        <div class="d-flex align-items-center">
          <feather-icon
            icon="ClipboardIcon"
            size="25"
            stroke-width="1"
            class="mr-1"
          />
          <strong> {{ T("Web.Generic.Infocards.Types.Account") }}s</strong>
        </div>
        <hr />
        <posting-destination-list-item
          v-for="infocard in accountInfoCards"
          :highlighted-line-id="highlightedLineId"
          :key="infocard.id"
          :infocard="infocard"
          @show-posting-history="openShowInfoCardPostingHistoryModal"
          :postings="getInfocardPostings(infocard)"
          @posting-clicked="(posting) => $emit('posting-clicked', posting)"
          @remove-posting-clicked="
            (posting) => $emit('remove-posting-clicked', posting)
          "
          @line-dropped-to-destination="
            (event) => $emit('line-dropped-to-destination', event)
          "
          @highlight-line="(event) => $emit('highlight-line', event)"
          @scroll-to-line="(event) => $emit('scroll-to-line', event)"
          :disabled="disabled"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import { BSpinner, BCard } from "bootstrap-vue";
import { mapGetters } from "vuex";
import PostingDestinationListItem from "./PostingDestinationListItem.vue";
import ShowInfoCardPostingHistoryModal from "../modals/ShowInfoCardPostingHistoryModal.vue";
export default {
  props: {
    locationScopeId: {
      required: true,
    },
    postings: {
      required: false,
      default: [],
    },
    highlightedLineId: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  components: {
    BSpinner,
    BCard,
    PostingDestinationListItem,
    ShowInfoCardPostingHistoryModal,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ infocards: "infocards/list" }),
    accountInfoCards() {
      return this.infocards.filter((i) => i.type == "AccountInfoCard");
    },
    containerInfoCards() {
      return this.infocards.filter((i) => i.type == "ContainerInfoCard");
    },
  },
  methods: {
    async openShowInfoCardPostingHistoryModal(subject) {
      await this.$refs.showInfoCardPostingHistoryModal.open(subject);
    },
    getInfocardPostings(infocard) {
      return this.postings.filter(
        (p) => p.postingDestinationInfoCardId == infocard.id
      );
    },
  },
};
</script>   
