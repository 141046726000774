<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mt-2 mb-2"
      v-if="loading || !invoice"
    >
      <b-spinner />
    </div>
    <div id="invoice-container" v-else>
      <b-card no-body class="invoice-preview-card">
        <b-card-body class="invoice-padding invoice-head pb-0">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <p class="font-weight-bold">
                {{ T("Web.Generic.Invoices.Reciver", "Receiver") }}
              </p>
              <p class="card-text mb-25">{{ invoice.customer.name }}</p>
              <p class="card-text mb-25">
                {{ invoice.customer.address }}
              </p>
              <p class="card-text mb-25">
                {{ invoice.customer.zip }} {{ invoice.customer.city }}
              </p>
              <p class="card-text mb-25">{{ invoice.customer.cvr }}</p>
              <template
                v-if="
                  invoice.deliveryLocation &&
                  invoice.deliveryLocation.address != ' '
                "
              >
                <br />
                <p class="font-weight-bold">
                  {{
                    T(
                      "Web.Generic.Invoices.DeliveryAddress",
                      "Delivery address"
                    )
                  }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.deliveryLocation.name }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.deliveryLocation.address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.deliveryLocation.zip }}
                  {{ invoice.deliveryLocation.city }}
                </p>
              </template>
              <template
                v-if="
                  invoice.orderReferenceId && invoice.orderReferenceId != ''
                "
              >
                <br />
                <p class="font-weight-bold">
                  {{
                    T(
                      "Web.Generic.Invoices.CustomerOrderNumber",
                      "Customer order number"
                    )
                  }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.orderReferenceId }}
                </p>
              </template>
              <template v-if="invoice.note && invoice.note != ''">
                <br />
                <p class="font-weight-bold mb-0">
                  {{ T("Web.Generic.Invoices.InvoiceNote", "Note") }} /p>
                </p>

                <p class="card-text mb-25">{{ invoice.note }}</p>
              </template>
            </div>
            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 class="invoice-title">
                {{ getDocumentTypeDisplayText(invoice.documentType) }}
                <span class="invoice-number">#{{ invoice.invoiceNumber }}</span>
              </h4>
              <div>
                <p class="font-weight-bold">
                  {{ T("Web.Generic.Invoices.Sender", "Sender") }}
                </p>
                <p class="card-text mb-25">{{ invoice.supplier.name }}</p>
                <p class="card-text mb-25">
                  {{ invoice.supplier.address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.supplier.zip }}
                  {{ invoice.supplier.city }}
                </p>
                <p class="card-text">{{ invoice.supplier.cvr }}</p>
                <br />
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ T("Web.Generic.Invoices.InvoiceDate", "Date") }}
                  </p>
                  <p class="invoice-date">
                    {{ format_date(invoice.issueDate) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
        <flex-table>
          <template #header>
            <span> {{ T("Web.Generic.Invoices.LineDate", "Date") }}</span>
            <span>{{
              T("Web.Generic.Invoices.LineDescription", "Description")
            }}</span>
            <span>{{ T("Web.Generic.Invoices.LineAmount", "Amount") }}</span>
            <span>{{ T("Web.Generic.Invoices.LineUnit", "Unit") }}</span>
            <span>{{
              T("Web.Generic.Invoices.LineUnitPrice", "Unit price")
            }}</span>
            <span>{{
              T("Web.Generic.Invoices.LineTotal", "Total price")
            }}</span>
            <span class="action"></span>
          </template>
          <template #body>
            <div
              :id="'line|' + line.id"
              class="flex-row"
              v-tooltip="postedToolTip(line)"
              :draggable="true"
              @dragstart="startDrag($event, line)"
              v-for="line in invoice.invoiceLines"
              :key="line.id"
              :class="{
                'line-posted': lineIsPosted(line),
                'line-highligted': highlightedLineId == line.id,
              }"
              @mouseenter="handleLineMouseEnter(line.id)"
              @mouseleave="handleLineMouseLeave(line.id)"
            >
              <span>{{ format_date(line.date) }}</span>
              <span
                >{{ line.text }}
                <small>{{ line.description }}</small>
              </span>
              <span>{{ line.amount | toCurrency }}</span>
              <span>{{ line.unit }}</span>
              <span>{{ line.priceAmount | toCurrency }}</span>
              <span>{{ line.priceTotal | toCurrency }}</span>
              <span class="action">
                <feather-icon
                  v-if="!isEmptyPosting(line)"
                  @click="
                    $emit('scroll-to-posting', getFirstPostingIdOnLine(line.id))
                  "
                  icon="SearchIcon"
                  size="16"
                  class="search-icon"
                />
              </span>
            </div>
          </template>
        </flex-table>
        <!-- Spacer -->
        <hr class="invoice-spacing" />
        <!-- Note -->
        <b-card-body class="invoice-padding pt-0">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Invoices.InvoiceNote", "Note") }}
          </span>
          <span>{{ invoice.note }}</span>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import { BCard, BSpinner, BCardBody } from "bootstrap-vue";
import { mapGetters } from "vuex";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import Logo from "@/@core/layouts/components/Logo.vue";
export default {
  props: {
    invoice: {
      required: true,
    },
    postings: {
      required: true,
    },
    highlightedLineId: {
      required: true,
    },
  },
  components: {
    BCard,
    BSpinner,
    BCardBody,
    FlexTable,
    Logo,
  },
  computed: {
    ...mapGetters({
      loading: "economicsInvoices/isLoading",
      locations: "locationsOld/list",
    }),
  },
  methods: {
    startDrag(event, line) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("lineId", line.id);
      event.dataTransfer.setData("lineText", line.text);
      event.dataTransfer.setData(
        "supplierContactId",
        this.invoice.supplierContactId
      );
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`);
    },
    isEmptyPosting(line) {
      let posting = this.postings.find(
        (elm) => elm.postingSubjectLineId == line.id
      );
      if (posting == undefined) return true;

      if (posting.type === "EmptyPosting") return true;

      return false;
    },
    getFirstPostingIdOnLine(lineId) {
      let posting = this.postings.find(
        (elm) => elm.postingSubjectLineId == lineId
      );
      return posting.id;
    },
    lineIsPosted(line) {
      return this.postings.some((elm) => elm.postingSubjectLineId == line.id);
    },
    postedToolTip(line) {
      /*
      if (this.locations.length <= 0) return;

      const posting = this.postings.find(
        (elm) => elm.postingSubjectLineId == line.id
      );
      if (posting == undefined) return "";

      let location = this.locations.find(
        (l) =>
          l.infoCards.length > 0 &&
          l.infoCards.filter((i) => i.id == posting.accountId).length > 0
      );
      if (location == undefined) return "";
      let infocard = location.infoCards.find((i) => i.id == posting.accountId);

      return this.$t("InvoicePreviewCol.Tooltip", {
        locationName: location.name,
        infocardName: infocard.name,
      });
      */
      return "";
    },
    handleLineMouseEnter(lineId) {
      this.$emit("line-hover-enter", lineId);
    },

    handleLineScroll(lineId) {
      this.$emit("line-scroll-event", lineId);
    },
    handleLineMouseLeave(lineId) {
      this.$emit("line-hover-leave", lineId);
    },
  },
};
</script>
<style>
.flex-table .header {
  padding: 10px 0 10px 10px;
}
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";

.flex-table {
  .header {
    color: #fff;
    background-color: $primary !important;
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.search-icon {
  &:hover {
    cursor: pointer;
  }
}

.invoice-head {
  background-color: #cadac8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.groups {
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-radius: 0.428rem;

  .item-center {
    padding: 10px;

    h4 {
      margin: 0;
    }
  }
}

.flex-table span {
  padding: unset;
  border: unset;
}

.flex-table .flex-row {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #ccc;
}

.line-posted {
  background-color: #cadac8 !important;
}

.line-highligted {
  background-color: #ffe083 !important;
}

.action {
  padding-top: 5px !important;
  width: 5% !important;
  justify-content: center !important;
}

body {
  &.dark-layout {
    .card-body {
      background-color: #2c8d5f;
    }

    .line-posted {
      background-color: #2c8d5f !important;
    }

    .line-highligted {
      background-color: #89722e !important;
    }
  }
}

.invoice-preview-card {
  margin-bottom: unset;
}
</style>
