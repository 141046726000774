<template>
  <div>
    <v-select
      v-if="options"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :taggable="taggable"
      :placeholder="placeholder"
      :options="options"
      :label="label"
      :reduce="(item) => item.id"
      @option:created="handleOptionCreated"
      v-model="content"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { uuid } from "vue-uuid";

export default {
  components: { vSelect },
  props: {
    vuexNamespace: { required: true },
    value: {
      required: true,
    },
    label: {
      default: "name",
      required: false,
    },
    placeholder: { required: false },
    taggable: {
      required: false,
      default: true,
    },
  },
  async created() {
    await this.$store.dispatch(`${this.vuexNamespace}/fetchList`);
    this.content = this.value
  },
  data() {
    return {
      content: this.data,
    };
  },
  computed: {
    options() {
      return this.$store.getters[`${this.vuexNamespace}/list`];
    },
  },
  methods: {
    handleOptionCreated(option) {
      if (typeof option !== "object") {
        option = { id: uuid.v4(), [this.label]: option };
      }
      this.$store.dispatch(`${this.vuexNamespace}/create`, {
        data: option,
        resourceId: option.id,
      });
      this.content = option.id;
      this.handleInput();
    },
    handleInput() {
      this.$emit("input", this.content);
    },
  },

  watch: {
    content() {
      this.handleInput();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 