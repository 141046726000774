<template>
  <page-loader :loading="loading">
    <simple-reconciliation-view
      :reconciliation-id="reconciliationId"
      @soft-save-clicked="$emit('reconciliation-saved')"
      @saved-and-closed="$emit('reconciliation-saved')"
      @go-back="$emit('reconciliation-saved')"
    />
  </page-loader>
</template>

<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ReconciliationView from "../../reconciliation/ReconciliationView.vue";
import SimpleReconciliationView from "../../reconciliation/SimpleReconciliationView.vue";
export default {
  components: { PageLoader, ReconciliationView, SimpleReconciliationView },
  props: {
    reconciliationId: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    this.loading = false;
  },
  computed: {
    newLayoutMode: {
      get() {
        return this.getUserSetting("reconciliation.layoutMode", false);
      },
      async set(value) {
        await this.setUserSettingAsync("reconciliation.layoutMode", value);
      },
    },
  },
};
</script>

<style>
.bar {
  top: 0px !important;
}
</style>