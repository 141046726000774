<template>
  <span
    class="rounded-circle b-avatar"
    :style="`--element-color: ${bgColor}; --element-txtcolor: ${txtColor}; --element-hoverbgColor: ${hoverbgColor}; --element-hovertxtColor: ${hovertxtColor};`"
    :class="{ 'hover-effect': hasClickListener }"
    @click="$emit('click')"
  >
    <feather-icon :icon="icon" size="12" />
  </span>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  props: {
    icon: {
      required: true,
    },
    bgColor: {
      required: false,
      default: "#f3f2f7",
    },
    txtColor: {
      required: false,
      default: "#6e6b7b",
    },
    hoverbgColor: {
      required: false,
      default: "#407e3d",
    },
    hovertxtColor: {
      required: false,
      default: "white",
    },
  },
  components: { FeatherIcon },
  computed: {
    hasClickListener() {
      return true;
      return this.$listeners && this.$listeners.click;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  color: var(--element-txtcolor);

  background-color: var(--element-color);

  &.hover-effect:hover {
    background-color: var(--element-hoverbgColor);
    color: var(--element-hovertxtColor);
  }
}
</style>