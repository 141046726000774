<template>
  <transition name="fade" mode="out-in">
    <div class="d-flex justify-content-center mt-2 mb-2" v-if="loading">
     <loader />
    </div>
    <slot v-else />
  </transition>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import Loader from './Loader.vue';
export default {
  components: {
    BSpinner,
    Loader,
  },
  props: {
    loading: {
      default: false,
      required: false,
    },
  },
};
</script>
