<template>
  <div class="wrapper col-md-6">
    <b-card class="LocationReconciliationList">
      <b-row>
        <b-col cols="6" v-if="locations.length > 0">
          <v-select
            :placeholder="T('Web.Generic.Locations.SelectLocation')"
            :clearable="false"
            v-model="location"
            label="name"
            :options="locations"
            @input="locationChange"
          />
        </b-col>
        <b-col cols="3">
          <b-button
            variant="primary"
            @click="openLocationCreateModal"
            class="w-100"
          >
            {{ T("Web.Generic.Locations.CreateLocation") }}
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-button v-if="locations.length > 0" 
            variant="primary" @click="openinfoCardCreateModal" class="w-100" >
            {{ T("Web.Generic.Infocards.CreateInfocard") }}
          </b-button>
        </b-col>
      </b-row>
      <create-location-modal ref="locationCreateModal" />
      <info-card-create-modal ref="infoCardCreateModal" />
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { BSpinner, BCard, BButton, BRow, BCol } from "bootstrap-vue";
import CreateLocationModal from "@/components/modals/locations/CreateLocationModal.vue";
import InfoCardCreateModal from "@/components/modals/infoCards/CreateInfoCardModal.vue";

import vSelect from "vue-select";
export default {
  props: {
    locations: {
      required: true,
    },
    postings: {
      required: true,
    },
    selectedLocation: {
      required: true,
    },
  },
  data() {
    return {
      location: null,
    };
  },
  components: {
    BSpinner,
    BButton,
    BCard,
    vSelect,
    CreateLocationModal,
    InfoCardCreateModal,
    BRow,
    BCol,
  },
  created() {
    if (this.postings.length && this.selectedLocation == null) {
      let location = this.locations.find(
        (l) =>
          l.infoCards.length > 0 &&
          l.infoCards.filter((i) => i.id == this.postings[0].accountId).length >
            0
      );

      if (location != undefined) {
        this.location = location;
        this.$emit("change-selected-location", { location: location });
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      realoadLocations: "locationsOld/reloadLocationsInfoCards",
    }),
    async openinfoCardCreateModal() {
      const result = await this.$refs.infoCardCreateModal.open(
        this.selectedLocation
      );
      if (result) {
        this.realoadLocations();
      }
    },
    async openLocationCreateModal() {
      await this.$refs.locationCreateModal.open();
    },
    locationChange(event) {
      this.$emit("change-selected-location", { location: event });
    },
  },
};
</script>

<style lang="scss" scoped>
.LocationReconciliationList {
  margin: 0;
  .card-body {
    padding: 0;
  }
}
.NoLocations {
  cursor: pointer;
}
.createContainer {
  display: flex;
  span {
    display: block;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    &.openLocationCreateModal {
      &::after {
        content: " ";
        height: 2px;
        padding-right: 5px;
        border-right: 1px solid #ccc;
      }
    }
  }
}
.create-location-card {
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>