<template>
  <b-modal
    scrollable
    :id="id"
    :size="size"
    :title="title"
    :hide-footer="true"
    :body-class="bodyClass"
    no-close-on-backdrop
    :hide-header-close="hideHeaderClose"
    @shown="handleShown"
  >
    <slot></slot>

    <div class="modal-footer">
      <slot name="modal-footer" />
    </div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    centered: {
      required: false,
      type: Boolean,
      default: true,
    },
    size: {
      required: false,
      type: String,
      default: "lg",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    bodyClass: {
      required: false,
      type: String,
      default: "",
    },
    hideFooter: {
      required: false,
      default: false,
    },
    hideHeaderClose:{
      required: false,
      default: true
    }
  },
  components: {
    BModal,
  },
  methods: {
    handleShown() {
      const elem = document.getElementById(`${this.id}___BV_modal_content_`);
      elem.removeAttribute("tabindex");
    },
  },
};
</script>
