<template>
  <treeselect
    v-if="ewcs && ewcs.length > 0"
    :multiple="false"
    :options="ewcGroupList"
    :defaultOptions="ewcGroupList"
    :disable-branch-nodes="true"
    :show-count="true"
    v-model="content"
    :async="true"
    :load-options="loadOptions"
    :placeholder="T('Web.Generic.EWCCodes.SelectEWCCode')"
    search-nested
    :disabled="disabled"
  />
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { mapGetters, mapActions } from "vuex";
import { uuid } from "vue-uuid";

export default {
  components: {
    Treeselect,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      content: this.value,
    };
  },
  async created() {
    await this.fetchEwcs();
    this.content = this.value;
  },
  computed: {
    ...mapGetters({ ewcs: "ewcs/list" }),
    ewcGroupList() {
      return this.getEwcGroupeList("");
    },
  },
  methods: {
    ...mapActions({
      fetchEwcs: "ewcs/fetchList",
    }),
    loadOptions({ action, searchQuery, callback }) {
      callback(null, this.getEwcGroupeList(searchQuery));
    },
    getEwcGroupeList(searchTerm = "") {
      let baseList = this.ewcs;
      if (searchTerm != "") {
        baseList = this.ewcs.filter(
          (e) =>
            e.code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.eCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            e.hCode.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }

      const list = baseList
        .filter((e) => e.alias == null)
        .reduce(function (rv, x) {
          (rv[x["categoryName"]] = rv[x["categoryName"]] || {
            label: x["categoryName"],
            children: [],
            id: uuid.v4(),
            isDefaultExpanded: searchTerm != "",
          }).children.push({
            id: x.id,
            code: x.code,
            label: `${x.code} | ${x.name}`,
          });
          return rv;
        }, {});

      let resultList = Object.keys(list).map(function (key) {
        return list[key];
      });
      let filledEwcItems = [];
      baseList
        .filter((e) => e.alias != null)
        .forEach((item) =>
          filledEwcItems.push({
            id: item.id,
            code: item.code,
            label: `${item.code} | ${item.alias}`,
          })
        );
      resultList.unshift({
        id: "filled",
        label: "Filled",
        children: filledEwcItems,
        isDefaultExpanded: true,
      });
      return resultList;
    },
  },
  watch: {
    content(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>