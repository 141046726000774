<template>
  <dragablemodal
    ref="ConfirmWasteCollectionModal"
    :height="'auto'"
    :width="900"
    name="confirm-wasteCollection-modal"
  >
    <header>
      <h4 class="text-center p-0 m-0">
        {{
          T(
            "Web.Generic.Invoices.Reconciliations.ConfirmWasteCollectionPopup.Title",
            "More information is needed"
          )
        }}
      </h4>
    </header>
    <div class="modals-content" v-if="posting != null">
      <p class="text-center">
        {{
          T(
            "Web.Generic.Invoices.Reconciliations.ConfirmWasteCollectionPopup.ChooseNoOfCollections",
            "Choose the number of collections in this posting"
          )
        }}
      </p>
      <b-tabs pills justified class="w-100" @activate-tab="handleActivateTab">
        <b-tab :title="`${posting.postingQuantity} ${T('Web.Generic.Postings.Collections', 'Collections')}`">
          <b-table-simple small>
            <b-thead>
              <b-tr>
                <b-th colspan="2">{{T("Web.Generic.Postings.Posting", "Posting")}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{T("Web.Generic.Postings.CollectionsCount", "Collection counts")}}</b-td>
                <b-td>{{ posting.postingQuantity | toCurrency }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>{{T("Web.Generic.Postings.TonnageCollected", "Tonnage collected")}} </b-td>
                <b-td>
                  <b-form-input
                    type="number"
                    value="0"
                    size="sm"
                    @change="(val) => (selectedOption.tonsWasteCollected = val)"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab
          :title="`1 ${T('Web.Generic.Postings.Collection', 'Collection')}`"
          active
          @change="selectedOption = { p: '22' }"
        >
          <b-table-simple small>
            <b-thead>
              <b-tr>
                <b-th colspan="2">{{T("Web.Generic.Postings.Posting", "Posting")}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{T("Web.Generic.Postings.CollectionsCount", "Collection counts")}}</b-td>
                <b-td>{{ 1 | toCurrency }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>{{T("Web.Generic.Postings.TonnageCollected", "Tonnage collected")}}  </b-td>
                <b-td>
                  <b-form-input
                    type="number"
                    size="sm"
                    :value="posting.postingQuantity"
                    @change="(val) => (selectedOption.tonsWasteCollected = val)"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
      </b-tabs>
      <div></div>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right mr-2"
          @click="handleConfirm"
          >{{T('Web.Generic.Confirm')}}</b-button
        >
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import {
  BButton,
  BTabs,
  BTab,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BTh,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    dragablemodal,
    BButton,
    BTabs,
    BTab,
    BTableSimple,
    BFormInput,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
  data() {
    return {
      posting: null,
      resolvePromise: undefined,
      rejectPromise: undefined,
      result: {
        wasteCollectioCount: undefined,
        tonsCollected: undefined,
      },
      selectedOption: undefined,
    };
  },
  methods: {
    async open(posting) {
      this.posting = posting;
      this.$refs.ConfirmWasteCollectionModal.show();
      this.selectedOption = {
        wasteCollectioCount: 1,
        tonsWasteCollected: posting.postingQuantity,
      };
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleActivateTab(index) {
      if (index == 0) {
        this.selectedOption = {
          wasteCollectioCount: this.posting.postingQuantity,
          tonsWasteCollected: 0,
        };
      } else {
        this.selectedOption = {
          wasteCollectioCount: 1,
          tonsWasteCollected: this.posting.postingQuantity,
        };
      }
    },
    handleConfirm() {
      this.resolvePromise(this.selectedOption);
      this.$refs.ConfirmWasteCollectionModal.hide();
    },
  },
};
</script>