<template>
  <div class="map-container" @mousedown.stop></div>
</template>

<script>
export default {
  props: {
    lng: {
      required: false,
      default: 0,
    },
    lat: {
      required: false,
      default: 0,
    },
  },
  mounted() {
    let myLatlng = new google.maps.LatLng(
      this.lat == 0 ? 56.0471309 : this.lat,
      this.lng == 0 ? 10.1751446 : this.lng
    );
    // Options
    let mapOptions = {
      zoom: this.lat == 0 ? 5.5 : 18,
      center: myLatlng,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: true,
    };
    // Apply options
    let map = new google.maps.Map(this.$el, mapOptions);
    let self = this;
    google.maps.event.addListener(map, "center_changed", function () {
      let lat = map.getCenter().lat();
      let lon = map.getCenter().lng();
      let newLatLng = { lat: lat, lng: lon };
      self.$emit("locationUpdated", newLatLng);
    });
  },
};
</script>

<style lang="scss" scoped>
.map-container::after {
  width: 40px;
  height: 40px;
  display: block;
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  background: url("~@/assets/images/crosshair.png");
  background-size: 40px 40px; /* Since I used the HiDPI marker version this compensates for the 2x size */
  pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.map-container {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid #d8d6de;
}
</style>
