var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-inner"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticStyle:{"width":"300px"}},[_vm._v("Gruppér efter")]),_c('b-form-select',{attrs:{"size":"sm","options":[
        { value: 'INFOCARDS', text: 'Infocards' },
        { value: 'FRACTIONS', text: 'Fractions' } ]},model:{value:(_vm.groupeUnit),callback:function ($$v) {_vm.groupeUnit=$$v},expression:"groupeUnit"}})],1),_c('hr'),_c('div',{staticClass:"posting-list"},_vm._l((_vm.postingsGroupedByType),function(categoryPostings,category){return _c('div',{key:category,staticClass:"posting-groupe"},[_c('div',{on:{"click":function($event){return _vm.handleCategoryClicked(category)}}},[_c('div',{staticStyle:{}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.PostingCategories." + category))+" ")]),_c('div',[(
              [
                'WasteCollection',
                'Rent',
                'WrongSorting',
                'Charge',
                'Income',
                'FutileDriving',
                'DeliveryOrRepatriation',
                'Exchange',
                'GoodsPurchase',
                'RepairPurchase',
                'SuppliesPurchase',
                'ContainerWash',
                'OtherFees',
                'OilSurchargeFee',
                'RegistrationFee' ].includes(category)
            )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit(categoryPostings, "Pcs"), 1 ))+" ")]),(
                !['Charge', 'Income', 'WasteCollection'].includes(category)
              )?_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Pcs")))]):_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  _vm.T('Web.Generic.PostingCategories.WasteCollection')
                ),expression:"\n                  T('Web.Generic.PostingCategories.WasteCollection')\n                ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"TruckIcon"}})],1)]):_vm._e(),(
              [
                'WasteCollection',
                'WrongSorting',
                'Income',
                'Charge',
                'DeliveryOrRepatriation',
                'Exchange' ].includes(category)
            )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalTonnage(categoryPostings), 3 )))]),_c('span',[_vm._v(" Ton")])]):_vm._e(),(
              [
                'WasteCollection',
                'WrongSorting',
                'Income',
                'Charge',
                'FutileDriving',
                'DeliveryOrRepatriation',
                'Exchange' ].includes(category)
            )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit(categoryPostings, "Hours"), 1 )))]),_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Hours")))])]):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getTotalPostingsPrice(categoryPostings, "Pcs"), 2 )))]),_c('span',[_vm._v(" DKK")])])]),_c('div',[(_vm.activeCategory == category)?_c('feather-icon',{attrs:{"icon":"ChevronUpIcon"}}):_c('feather-icon',{attrs:{"icon":"ChevronDownIcon"}})],1)]),(_vm.activeCategory == category)?_c('div',_vm._l((_vm.postingGroupedByInfocards(
            categoryPostings
          )),function(infocardPostings,infocardId){return _c('div',{key:(category + "_" + infocardId)},[_c('div',[_c('div',[_vm._v(_vm._s(_vm.getInfocardName(infocardId)))]),_c('div',[(
                  [
                    'WasteCollection',
                    'Rent',
                    'WrongSorting',
                    'Charge',
                    'Income',
                    'FutileDriving',
                    'DeliveryOrRepatriation',
                    'Exchange',
                    'GoodsPurchase',
                    'RepairPurchase',
                    'SuppliesPurchase',
                    'ContainerWash',
                    'OtherFees',
                    'OilSurchargeFee',
                    'RegistrationFee' ].includes(category)
                )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit(infocardPostings, "Pcs"), 1 ))+" ")]),(
                    !['Charge', 'Income', 'WasteCollection'].includes(
                      category
                    )
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Pcs"))+" ")]):_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                      _vm.T('Web.Generic.PostingCategories.WasteCollection')
                    ),expression:"\n                      T('Web.Generic.PostingCategories.WasteCollection')\n                    ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"TruckIcon"}})],1)]):_vm._e(),(
                  [
                    'WasteCollection',
                    'WrongSorting',
                    'Income',
                    'Charge',
                    'DeliveryOrRepatriation',
                    'Exchange' ].includes(category)
                )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalTonnage(infocardPostings), 3 )))]),_c('span',[_vm._v(" Ton")])]):_vm._e(),(
                  [
                    'WasteCollection',
                    'WrongSorting',
                    'Income',
                    'Charge',
                    'FutileDriving',
                    'DeliveryOrRepatriation',
                    'Exchange' ].includes(category)
                )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit(infocardPostings, "Hours"), 1 )))]),_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Hours")))])]):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getTotalPostingsPrice(infocardPostings, "Pcs"), 2 )))]),_c('span',[_vm._v(" DKK")])])]),_c('div')]),_c('div',_vm._l((infocardPostings),function(infocardPosting){return _c('div',{key:infocardPosting.id},[_c('div',[_vm._v(_vm._s(_vm.format_date(infocardPosting.postingDate)))]),_c('div',[(
                    [
                      'WasteCollection',
                      'Rent',
                      'WrongSorting',
                      'Income',
                      'Charge',
                      'FutileDriving',
                      'DeliveryOrRepatriation',
                      'Exchange',
                      'GoodsPurchase',
                      'RepairPurchase',
                      'SuppliesPurchase',
                      'ContainerWash',
                      'OtherFees',
                      'OilSurchargeFee',
                      'RegistrationFee' ].includes(category)
                  )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit([infocardPosting], "Pcs"), 1 ))+" ")]),(
                      !['Charge', 'Income', 'WasteCollection'].includes(
                        category
                      )
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Pcs")))]):_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                        _vm.T('Web.Generic.PostingCategories.WasteCollection')
                      ),expression:"\n                        T('Web.Generic.PostingCategories.WasteCollection')\n                      ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"TruckIcon"}})],1)]):_vm._e(),(
                    [
                      'WasteCollection',
                      'WrongSorting',
                      'Income',
                      'Charge',
                      'DeliveryOrRepatriation',
                      'Exchange' ].includes(category)
                  )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalTonnage([infocardPosting]), 3 )))]),_c('span',[_vm._v(" Ton")])]):_vm._e(),(
                    [
                      'WasteCollection',
                      'WrongSorting',
                      'Income',
                      'Charge',
                      'FutileDriving',
                      'DeliveryOrRepatriation',
                      'Exchange' ].includes(category)
                  )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getPostingsTotalFromUnit([infocardPosting], "Hours"), 1 )))]),_c('span',[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Units.Hours")))])]):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCurrencyString( _vm.getTotalPostingsPrice([infocardPosting]), 2 )))]),_c('span',[_vm._v(" DKK")])])]),_c('div')])}),0)])}),0):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }