<template>
  <dragablemodal
    ref="AddInvoiceLineModal"
    :height="'auto'"
    :width="900"
    name="add-invoice-line-modal"
    :id="'add-invoice-line-modal'"
  >
    <header class="font-weight-bold">
      {{ T("Web.Reconciliations.AddInvoiceLine", "Add invoice line") }}
    </header>
    <div class="modals-content" style="overflow: visible">
      <page-loader :loading="isLoading">
        <div class="d-flex line-con" v-if="localLine != null">
          <b-form-group :label="T('Web.Generic.Invoices.LineDate', 'Date')">
            <b-form-datepicker
              v-model="localLine.date"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            :label="T('Web.Generic.Invoices.LineDescription', 'Description')"
          >
            <b-form-input
              type="text"
              size="sm"
              v-model="localLine.text"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="T('Web.Generic.Invoices.LineAmount', 'Amount')">
            <b-form-input
              type="number"
              size="sm"
              v-model="localLine.amount"
              @change="localLine.amount = formatCurrency(localLine.amount, 3)"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="T('Web.Generic.Invoices.LineUnit', 'Unit')">
            <b-form-input
              type="text"
              size="sm"
              v-model="localLine.unit"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="T('Web.Generic.Invoices.LineUnitPrice', 'Unit price')"
          >
            <b-form-input
              type="number"
              size="sm"
              v-model="localLine.priceAmount"
              @change="
                localLine.priceAmount = formatCurrency(localLine.priceAmount)
              "
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="T('Web.Generic.Invoices.LineTotal', 'Total price')"
          >
            <b-form-input
              type="number"
              size="sm"
              v-model="localLine.priceTotal"
              @change="
                localLine.priceTotal = formatCurrency(localLine.priceTotal)
              "
              lazy
            ></b-form-input>
          </b-form-group>
        </div>
      </page-loader>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          @click="executeAddLine"
          :disabled="!canSubmit"
        >
          {{ T("Web.Generic.Save") }}
        </b-button>
        <b-button variant="light" class="float-right mr-2" @click="cancel">
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import {
  BFormInput,
  BFormDatepicker,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";
import ResourceService from "@/services/base/resource.service";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    dragablemodal,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BButton,
    PageLoader,
  },
  data() {
    return {
      localLine: {
        date: "",
        text: "",
        unit: "",
        amount: 0,
        priceAmount: 0,
        priceTotal: 0,
      },
      invoiceId: null,
      isLoading: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  computed: {
    canSubmit() {
      return (
        this.localLine.date != "" &&
        this.localLine.text != "" &&
        this.localLine.unit != ""
      );
    },
  },
  methods: {
    async open(invoiceId) {
      this.invoiceId = invoiceId;
      this.localLine.amount = this.formatCurrency(this.localLine.amount, 3);
      this.localLine.priceAmount = this.formatCurrency(
        this.localLine.priceAmount
      );
      this.localLine.priceTotal = this.formatCurrency(
        this.localLine.priceTotal
      );
      this.localLine.id = uuid.v4();
      this.$refs.AddInvoiceLineModal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    cancel() {
      this.close();
      return this.resolvePromise(false);
    },
    close() {
      this.$refs.AddInvoiceLineModal.hide();
      this.line = null;
      this.invoiceId = null;
      this.localLine = {
        date: "",
        text: "",
        unit: "",
        amount: 0,
        priceAmount: 0,
        priceTotal: 0,
      };
    },
    formatCurrency(value, decimal = 2) {
      let floatValue = parseFloat(value);
      return floatValue < 0.01 ? floatValue.toFixed(3) : floatValue.toFixed(2);
    },
    async executeAddLine() {
      try {
        this.isLoading = true;
        const invoiceService = new ResourceService(
          `economics/invoices/${this.invoiceId}/lines`
        );
        await invoiceService.post("", this.localLine);
        var lineResult = { ...this.localLine };
        lineResult.amount = parseFloat(lineResult.amount);
        lineResult.priceAmount = parseFloat(lineResult.priceAmount);
        lineResult.priceTotal = parseFloat(lineResult.priceTotal);
        this.close();
        return this.resolvePromise(lineResult);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style >
#add-invoice-line-modal .vm--modal {
  overflow: visible !important;
}
</style>
<style lang="scss" scoped>
.line-con {
  gap: 10px;

  fieldset {
    &:first-child {
      width: 150px;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      width: 70px;
    }
    &:nth-child(4) {
      width: 70px;
    }
    &:nth-child(6) {
      width: 150px;
    }
    &:nth-child(7) {
      width: 150px;
    }
  }
}
</style>