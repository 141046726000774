<template>
  <div class="map-container" @mousedown.stop></div>
</template>

<script>
export default {
  props: {
    lng: {
      required: false,
      default: 0,
    },
    lat: {
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    let myLatlng = new google.maps.LatLng(
      this.lat == 0 ? 56.0471309 : this.lat,
      this.lng == 0 ? 10.1751446 : this.lng
    );
    // Options
    let mapOptions = {
      zoom: this.lat == 0 ? 5.5 : 18,
      center: myLatlng,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    };
    // Apply options
    this.map = new google.maps.Map(this.$el, mapOptions);
    let self = this;
    google.maps.event.addListener(this.map, "dragend", function () {
      let lat = self.map.getCenter().lat();
      let lon = self.map.getCenter().lng();
      let newLatLng = { lat: lat, lng: lon };
      self.$emit("locationUpdated", newLatLng);
    });
  },
  methods: {
    updateMapCenter() {
      this.map.setCenter(new google.maps.LatLng(this.lat, this.lng));
    },
  },

  watch: {
    lat(newValue, oldValue) {
      if (newValue != oldValue) this.updateMapCenter();
    },
    lat(newValue, oldValue) {
      if (newValue != oldValue) this.updateMapCenter();
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container::after {
  width: 40px;
  height: 40px;
  display: block;
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  background: url("~@/assets/images/crosshair.png");
  background-size: 40px 40px; /* Since I used the HiDPI marker version this compensates for the 2x size */
  pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.map-container {
  width: 100%;
  height: 100%;
}
</style>
