<template>
  <div class="wrap">
    <show-info-card-posting-history-modal
      ref="showInfoCardPostingHistoryModal"
    />
    <div v-if="infocards && infocards.length == 0">
      <h3 class="text-center text-muted">
        {{ $t("Generic.NoInfocardsOnLocation") }}
      </h3>
      <h6 class="text-center text-muted">
        {{ $t("Generic.ReconciliationCreateInfocard") }}
      </h6>
    </div>
    <b-card class="d-flex" v-if="infocards && containerInfoCards.length > 0">
      <div class="d-flex align-items-center">
        <feather-icon icon="BoxIcon" size="25" stroke-width="1" class="mr-1" />
        <strong> {{ $t("Generic.CONTAINERSFRACTIONS") }}</strong>
      </div>
      <hr />
      <div
        class="info-card-con"
        v-for="infocard in containerInfoCards"
        :key="infocard.id"
        @drop="(event) => handleLineToAccountDrop(event, infocard.id)"
        @dragover.prevent=""
        @dragleave.prevent=""
      >
        <div
          class="head d-flex justify-content-between align-items-center"
          :class="{
            hovering: preview.indexOf(infocard.id) > -1,
            'with-postings': getPostingsForAccount(infocard.id).length > 0,
          }"
          @dragover="toggleOnDrag(infocard.id)"
          @dragleave="collapseIfNotPinned(infocard.id)"
        >
          <span>{{ infocard.name }}</span>
          <small
            >{{ infocard.refId }}
            <feather-icon
              icon="InfoIcon"
              class="ml-1"
              v-b-tooltip.hover.right="
                $t('Generic.LastPostingsText') + '\'' + infocard.name + '\''
              "
              @click="openShowInfoCardPostingHistoryModal(infocard)"
            />
          </small>
        </div>
        <div
          class="posting-list"
          v-if="getPostingsForAccount(infocard.id).length > 0"
        >
          <flex-table class="table" :showHeader="false">
            <template #body>
              <div class="postings-row">
                <reconcilation-postings-list
                  v-if="postings"
                  :postings="getPostingsForAccount(infocard.id)"
                  :highlighted-line-id="highlightedLineId"
                  @posting-hover-enter="
                    (posting) => $emit('posting-hover-enter', posting)
                  "
                  @posting-hover-leave="$emit('posting-hover-leave', null)"
                  @remove-posting-clicked="
                    (event) => $emit('remove-posting-clicked', event)
                  "
                  @posting-clicked="
                    (posting) => $emit('posting-clicked', posting)
                  "
                  @posting-scroll-event="(lineId) => $emit('posting-scroll-event', lineId)"
                  :disabled="disabled"
                />
              </div>
            </template>
          </flex-table>
        </div>
      </div>
    </b-card>

    <b-card class="d-flex" v-if="accountInfoCards.length > 0">
      <div class="d-flex align-items-center">
        <feather-icon
          icon="ClipboardIcon"
          size="25"
          stroke-width="1"
          class="mr-1"
        />
        <strong class="textupper">{{ $t("Generic.Accounts") }}</strong>
      </div>
      <hr />
      <div
        class="info-card-con"
        v-for="infocard in accountInfoCards"
        :key="infocard.id"
        @drop="(event) => handleLineToAccountDrop(event, infocard.id)"
        @dragover.prevent=""
        @dragleave.prevent=""
      >
        <div
          class="head d-flex justify-content-between align-items-center"
          :class="{
            hovering: preview.indexOf(infocard.id) > -1,
            'with-postings': getPostingsForAccount(infocard.id).length > 0,
          }"
          @dragover="toggleOnDrag(infocard.id)"
          @dragleave="collapseIfNotPinned(infocard.id)"
        >
          <span>{{ infocard.name }}</span>
          <small
            >{{ infocard.refId }}
            <feather-icon
              icon="InfoIcon"
              class="ml-1"
              v-b-tooltip.hover.right="
                $t('Generic.LastPostingsText') + '\'' + infocard.name + '\''
              "
              @click="openShowInfoCardPostingHistoryModal(infocard)"
          /></small>
        </div>
        <div
          class="posting-list"
          v-if="getPostingsForAccount(infocard.id).length > 0"
        >
          <flex-table class="table" :showHeader="false">
            <template #body>
              <div class="postings-row">
                <reconcilation-postings-list
                  v-if="postings"
                  :postings="getPostingsForAccount(infocard.id)"
                  :highlighted-line-id="highlightedLineId"
                  @posting-hover-enter="
                    (posting) => $emit('posting-hover-enter', posting)
                  "
                  @posting-hover-leave="$emit('posting-hover-leave', null)"
                  @remove-posting-clicked="
                    (event) => $emit('remove-posting-clicked', event)
                  "
                  @posting-clicked="
                    (posting) => $emit('posting-clicked', posting)
                  "
                  :disabled="disabled"
                />
              </div>
            </template>
          </flex-table>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardTitle, BCardSubTitle } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import ReconcilationPostingsList from "./ReconcilationPostingsList.vue";
import { VBTooltip } from "bootstrap-vue";
import ShowInfoCardPostingHistoryModal from "../modals/ShowInfoCardPostingHistoryModal.vue";

export default {
  props: {
    location: {
      required: true,
    },
    postings: {
      required: true,
    },
    highlightedLineId: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    infocards:{
      required:false,
      default:[]
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    FlexTable,
    ReconcilationPostingsList,
    ShowInfoCardPostingHistoryModal,
  },
  data() {
    return {
      pinned: [],
      preview: [],
      displayCreateNotification: [],
      activeAccountId: null,
    };
  },
  computed: {
    accountInfoCards() {
      return this.infocards.filter((i) => i.type == "AccountInfoCard");
    },
    containerInfoCards() {
      return this.infocards.filter(
        (i) => i.type == "ContainerInfoCard"
      );
    },
  },
  methods: {
    async openShowInfoCardPostingHistoryModal(infoCard) {
      await this.$refs.showInfoCardPostingHistoryModal.open(infoCard);
    },
    toggleOnDrag(id) {
      const index = this.preview.indexOf(id);
      if (index > -1) return;

      this.preview.push(id);
    },
    collapseIfNotPinned(id) {
      const index = this.preview.indexOf(id);

      this.preview.splice(index, 1);
    },
    /** Pinned end */
    accountRecivedLineDrag(accountId, lineId) {
      this.$emit("line-draged-to-account", {
        lineId: lineId,
        accountId: accountId,
      });
    },
    /** Drop events */
    handleLineToAccountDrop(event, accountId) {
      if (this.disabled) return;
      this.preview = [];
      const index = this.pinned.indexOf(accountId);
      if (index == -1) this.pinned.push(accountId);

      this.activeAccountId = null;
      this.accountRecivedLineDrag(
        accountId,
        event.dataTransfer.getData("lineId")
      );
    },
    /** End dropevents */
    getPostingsForAccount(accountId) {
      return this.postings.filter(
        (item) => item.postingDestinationInfoCardId === accountId
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/base/colors.scss";
.textupper {
  text-transform: uppercase;
}
.info-card-con {
  margin-bottom: 5px;

  .head {
    color: #6e6b7b;
    background: #cadac8;
    border-radius: 4px;
    padding: 20px;
    font-weight: bold;
    &.with-postings {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    span {
      font-size: 18px;
    }
    &.hovering {
      background: #407e3d;
      color: #fff;
    }
  }
  .posting-list {
    border: 2px solid #ebe9f1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0px;
  }
}

body {
  &.dark-layout {
    .wrap {
      .card {
        .body {
          .highlight-account {
            span {
              background-color: #2c8d5f;
            }
          }
        }

        .create-infocard-con {
          background: #2c8d5f;
        }
      }
    }
  }
}

.wrap {
  .card {
    position: relative;

    .header {
      background-color: $primary;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #fff;

      &.hovering {
        background-color: #cadac8;
        border-top-left-radius: 0.428rem;
        border-top-right-radius: 0.428rem;
      }
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      .card-title {
        margin-bottom: 0px;
        color: #fff;
      }

      .collapse-toggle {
        cursor: pointer;
        display: none;
      }
    }

    .body {
      .highlight-account {
        span {
          font-weight: bold;
          background-color: #97e7c1;
        }
      }
    }

    .create-infocard-con {
      border: 1px dashed;
      background: #97e7c1;
      text-align: center;
      font-size: 12px;
      font-weight: bold;

      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 63px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>