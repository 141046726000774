<template>
  <div>
    <infocard-base-form v-model="infocard" />
    <b-row>
      <b-col cols="7">
        <b-form-group :label="T('Web.Generic.Infocards.Collector') + ' *'">
          <infocard-collector-picker v-model="infocard.collectorId" />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group
          :label="T('Web.Generic.Infocards.Placement', 'Placement')"
        >
          <b-form-input v-model="infocard.placement" />
        </b-form-group>
      </b-col>
      <b-col cols="7">
        <b-form-group
          :label="
            T('Web.Generic.Infocards.TransportVehicle', 'Transport vehicle')
          "
        >
          <infocard-transport-vehicle-picker
            v-model="infocard.transportVehicleId"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
          :label="
            T(
              'Web.Generic.Infocards.IncludeInStatistics',
              'Include in statistics'
            )
          "
        >
          <b-form-checkbox
            :unchecked-value="false"
            :value="true"
            v-model="infocard.includeInTransportStatics"
            switch
            size="lg"
          >
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col cols="5">
        <b-form-group :label="T('Web.Generic.Infocards.InternalGroupId')">
          <b-form-input v-model="infocard.internalGroupId" />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group :label="T('Web.Generic.Infocards.Quantity')">
          <b-form-input v-model="infocard.quantity" />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group :label="T('Web.Generic.Infocards.SupplierIdRefNumber')">
          <b-form-tags v-model="infocard.supplierIdRefNumber" input-id="tags-basic" :placeholder="T('Web.Generic.Add') + ' ' + T('Web.Generic.Infocards.SupplierIdRefNumber')" :addButtonText="T('Web.Generic.Add')" />
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="infocard.includeInTransportStatics">
        <b-form-group
          :label="T('Web.Generic.Infocards.PickupType', 'Pickup type')"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="infocard.pickupType"
            :options="pickupTypeOptions"
            button-variant="outline-primary"
            name="radios-btn-default"
            buttons
            size="sm"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <h6 class="content-area-title">
          {{ T("Web.Generic.Infocards.ContentHeadline", "Content (optional)") }}
        </h6>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="T('Web.Generic.Infocards.WasteCategory')">
          <b-form-select
            :options="wasteCategories"
            v-model="infocard.wasteCategory"
            :disabled="lockWasteFractions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="T('Web.Generic.Infocards.WasteFraction')">
          <appendable-select
            vuex-namespace="wasteFractions"
            :placeholder="
              T('Web.Generic.Infocards.SelectWasteFraction', 'Select fraction')
            "
            v-model="infocard.wasteFractionId"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="T('Web.Generic.Infocards.EWCCode')">
          <eak-picker
            v-model="infocard.ewcCodeId"
            :disabled="lockWasteFractions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="GHG">
          <b-form-select
            :disabled="lockWasteFractions"
            :options="ghgCodesOptions"
            v-model="infocard.ghgCodeId"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <transition name="fade" mode="out-in">
      <b-row v-if="!showAllFields">
        <b-col cols="12">
          <b-button
            variant="flat-secondary w-100 text-primary mt-1"
            @click="showAllFields = true"
          >
            <!-- {{ $t("ContainerInfoCardForm.ShowAllBtn") }}-->
            <feather-icon icon="ChevronDownIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <h6 class="content-area-title">
            {{
              T(
                "Web.Generic.Infocards.ContainerHeadline",
                "Container specific (optional)"
              )
            }}
          </h6>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="T('Web.Generic.Infocards.ContainerType', 'Container type')"
          >
            <appendable-select
              vuex-namespace="containerTypes"
              :placeholder="
                T(
                  'Web.Generic.Infocards.ChooseContainerType',
                  'Choose conteiner type'
                )
              "
              v-model="infocard.containerTypeId"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group :label="T('Web.Generic.Infocards.Size', 'Size')">
            <b-row>
              <b-col cols="9">
                <b-form-input type="number" v-model="infocard.containerSize" />
              </b-col>
              <b-col cols="3">
                <b-form-select
                  :options="containerSizeUnits"
                  v-model="infocard.containerSizeUnit"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <h6 class="content-area-title">
            {{ T("Web.Generic.Infocards.CollectionsHeader", "Collections") }}
          </h6>
        </b-col>
        <b-col cols="12" sm="7">
          <b-form-group :label="T('Web.Generic.Infocards.RecivingFacility')">
            <appendable-select
              :vuex-namespace="'recivingFacilities'"
              v-model="infocard.receivingFacilityId"
              :placeholder="
                T(
                  'Web.Generic.Infocards.ChooseRecivingFacility',
                  'Choose or create a Receiving facility'
                )
              "
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="5">
          <b-form-group
            :label="T('Web.Generic.Infocards.Pickupdays', 'Pickup days')"
          >
            <weekday-picker class="lg" v-model="infocard.pickupDays" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="T('Web.Generic.Infocards.ReceiverRef', 'Receiver ref')"
          >
            <b-form-textarea v-model="infocard.receiverRef" />
          </b-form-group>
        </b-col>
      </b-row>
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BFormTags,
} from "bootstrap-vue";
import InfocardBaseForm from "./InfocardBaseForm.vue";
import InfocardCollectorPicker from "./InfocardCollectorPicker.vue";
import InfocardTransportVehiclePicker from "./InfocardTransportVehiclePicker.vue";
import AppendableSelect from "@/app/components/AppendableSelect.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import EakPicker from "./EakPicker.vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BFormTextarea,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadioGroup,
    InfocardBaseForm,
    InfocardCollectorPicker,
    InfocardTransportVehiclePicker,
    AppendableSelect,
    WeekdayPicker,
    EakPicker,
    AppendableSelect,
    BFormTags,
  },
  props: {
    infocard: {
      required: true,
    },
  },
  async created() {
    await this.fetchGhgCodes();
    this.lockWasteFractions = (
      await new ResourceService("concern/settings").get("")
    ).data.data.lockInfocardBasedOnWasteFraction;
  },
  mounted() {
    this.$emit("submittable-status-changed", this.submittable);
  },
  data() {
    return {
      showAllFields: true,
      pickupTypeOptions: [
        {
          text: this.T(
            "Web.Generic.Infocards.PickupOptions.Transport",
            "Transport"
          ),
          value: 1,
        },
        {
          text: this.T(
            "Web.Generic.Infocards.PickupOptions.Location",
            "Location"
          ),
          value: 2,
        },
      ],
      ghgCodes: [],
      lockWasteFractions: false,
    };
  },
  computed: {
    submittable() {
      return (
        this.valueIsNotEmptyOrUnknown(this.infocard.name) &&
        this.valueIsNotEmptyOrUnknown(this.infocard.locationId) &&
        this.valueIsNotEmptyOrUnknown(this.infocard.collectorId)
      );
    },
    ghgCodesOptions() {
      let result = this.ghgCodes.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
      result.sort((a, b) => a.text.localeCompare(b.text));
      result.unshift({
        text: this.T("Web.Generic.WasteCategories.None"),
        value: "00000000-0000-0000-0000-000000000000",
      });
      return result;
    },
  },
  methods: {
    async fetchGhgCodes() {
      const service = new ResourceService("background-data/ghg-codes");
      let { data } = await service.get("");
      this.ghgCodes = data.data;
    },
  },
  watch: {
    submittable(newValue) {
      this.$emit("submittable-status-changed", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-area-title {
  color: #b9b9c3;
  padding: 10px 0px;
  margin: 10px 0px;
}
</style>