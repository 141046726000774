<template>
  <form @submit.stop.prevent="handleSubmit">
    <!--
    <h6 class="my-2 text-muted">
      {{ $t("AccountInfoCardCreateForm.Header") }}
    </h6>
    <b-card>
      <b-row>
        <b-col cols="12" sm="4">
          <b-form-group class="d-flex justify-content-start">
            <div @click="coverPath = undefined" v-if="coverPath">
              <b-avatar
                :src="imageBaseUrl + '/images/' + coverPath"
                class="mr-1"
                size="10rem"
              />
            </div>
            <cover-picker v-model="selectedCoverFile" v-else />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="8">
          <b-form-group :label="$t('Generic.Name')">
            <b-form-input v-model="account.name" required autofocus />
          </b-form-group>
          <b-form-group :label="$t('AccountInfoCardCreateForm.Location')">
            <b-form-input
              :value="selectedLocation.name"
              disabled
              v-if="locationLocked"
            />
            <location-picker-select v-else v-model="selectedLocation" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    -->
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BAvatar,
} from "bootstrap-vue";
import CoverPicker from "@/components/forms/infoCards/coverPicker/CoverPicker.vue";
import LocationPickerSelect from "./LocationPickerSelect.vue";
import ImagesService from "@/services/files/images-service";

import { mapActions } from "vuex";
export default {
  props: {
    location: {
      require: false,
    },
    infoCard: {
      required: false,
    },
    resourceId: {
      required: true,
    },
    name: {
      default: undefined,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BAvatar,
    CoverPicker,
    LocationPickerSelect,
  },
  mounted() {
    if (this.location) {
      this.selectedLocation = this.location;
      this.locationLocked = true;
    }
    if (this.infoCard) {
      (this.isCreate = false), (this.account = this.infoCard);
      this.selectedLocation = this.infoCard.location;
      this.locationLocked = true;
      this.coverPath = this.infoCard.coverPath;
    }
    if(this.name)
    {
      this.account.name = this.name;
    }
  },
  data() {
    return {
      imageBaseUrl: process.env.VUE_APP_APIURL,
      isCreate: true,
      selectedCoverFile: undefined,
      coverPath: undefined,
      selectedLocation: undefined,
      locationLocked: false,
      account: {
        type: "AccountInfoCard",
        name: undefined,
        locationId: undefined,
        coverPath: undefined,
        rentCost: undefined,
        costPrCollection: undefined,
        expensesPrTonCollected: undefined,
        revenuePrTonCollected: undefined,
        devilationTolerance: 0,
      },
    };
  },
  computed: {
    canSubmit() {
      return (
        this.valueIsNotEmptyOrUnknown(this.account.name) &&
        this.valueIsNotEmptyOrUnknown(this.account.locationId)
      );
    },
  },
  methods: {
    ...mapActions({
      createInfoCard: "infoCards/create",
      updateInfoCard: "infoCards/update",
    }),
    async submit() {
      if (this.selectedCoverFile) {
        const imgService = new ImagesService();
        this.account.coverPath = (
          await imgService.uploadSingle(this.selectedCoverFile)
        ).data.path;
      }
      if (this.isCreate)
        await this.createInfoCard({
          data: this.account,
          resourceId: this.resourceId,
        });
      else this.updateInfoCard({ data: this.account });
    },
  },
  watch: {
    selectedLocation(newValue) {
      if (!newValue) return (this.account.locationId = undefined);
      this.account.locationId = newValue.id;
    },
    canSubmit(newValue) {
      this.$emit("can-submit-status-changed", newValue);
    },
  },
};
</script>