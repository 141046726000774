<template>
  <portal to="dragable-modal">
    <modal
      :clickToClose="false"
      :name="modalName"
      :height="height"
      :width="width"
      :draggable="draggable"
      :class="{ top50: topClass }"
      :id="id"
      
    >
      <slot></slot>
    </modal>
  </portal>
</template>

<script>
import { uuid } from "vue-uuid";
export default {
  components: {},
  created() {
    if (this.isDraggable != undefined) this.draggable = this.isDraggable;
  },
  props: ["width", "height", "name", "topClass", "isDraggable", "id"],
  data() {
    return {
      draggable: true,
      visible: false,
      modalName: uuid.v4(),
    };
  },
  methods: {
    show() {
      this.$modal.show(this.modalName);
    },
    hide() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
<style lang="scss">
.top50 {
  top: 50%;
}
.vm--container {
  position: absolute !important;
  z-index: 99999;
  header {
    background-color: #f8f8f8;
  }
  .modals-content {
    overflow-y: auto;
    max-height: 800px;
    .card {
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
    }
  }
  .modals-content,
  header,
  footer {
    padding: 1rem;
  }
}
</style>