<template>
  <dragablemodal
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
    :name="refName"
  >
  <!--
    <header>{{ $t("CreateInfoCardModal.Title") }}</header>
    <div class="modals-content">
      <transition name="fade" mode="out-in">
        <div class="d-flex justify-content-center mt-2 mb-2" v-if="loading">
          <b-spinner />
        </div>
        <div v-else-if="type === undefined">
          <h2 class="text-muted py-2 text-center">
            {{ $t("CreateInfoCardModal.TypeOption") }}
          </h2>
          <div class="info-card-type-picker pb-2">
            <b-button
              variant="outline-primary"
              class="type-option"
              @click="type = 'ContainerInfoCard'"
            >
              <feather-icon icon="BoxIcon" size="36" stroke-width="1" />
              <span>{{ $t("CreateInfoCardModal.ContainerOrFraction") }}</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="type-option"
              @click="type = 'AccountInfoCard'"
            >
              <feather-icon icon="ClipboardIcon" size="36" stroke-width="1" />
              <span>{{ $t("Generic.Account") }}</span>
            </b-button>
          </div>
        </div>
        <container-info-card-create-form
          :resource-id="resourceId"
          :location="location"
          :infoCard="infoCard"
          :name="name"
          :supplier-contact-id="supplierContactId"
          v-else-if="type === 'ContainerInfoCard'"
          @can-submit-status-changed="(state) => (canSubmit = state)"
          ref="infoCardForm"
        />
        <account-info-card-create-form
          :resource-id="resourceId"
          :location="location"
          :infoCard="infoCard"
          :name="name"
          v-else-if="type === 'AccountInfoCard'"
          @can-submit-status-changed="(state) => (canSubmit = state)"
          ref="infoCardForm"
        />
      </transition>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit || submitting"
          @click="handleSubmit"
        >
          {{
            submitting
              ? $t("Generic.Creating")
              : $t("Generic.CreateBtn")
          }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ $t("Generic.Cancel") }}
        </b-button>
      </div>
    </footer>
    -->
  </dragablemodal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ContainerInfoCardCreateForm from "@/components/forms/infoCards/ContainerInfoCardCreateForm.vue";
import AccountInfoCardCreateForm from "@/components/forms/infoCards/AccountInfoCardCreateForm.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { mapActions, mapGetters } from "vuex";
import { uuid } from "vue-uuid";
import dragablemodal from "@/components/layout/dragablemodal.vue";

export default {
  props: {
    refName: {
      default: 'InfoCardCreateModal"',
    },
  },
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    ContainerInfoCardCreateForm,
    AccountInfoCardCreateForm,
    FeatherIcon,
    dragablemodal,
  },
  data() {
    return {
      resourceId: undefined,
      infoCard: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined,
      type: undefined,
      location: undefined,
      canSubmit: false,
      submitting: false,
      name: undefined,
      supplierContactId: undefined,
    };
  },
  computed: {
    ...mapGetters({
      loading: "infoCards/isLoading",
      getInfoCard: "infoCards/byId",
    }),
  },
  methods: {
    ...mapActions({ fetchInfoCard: "infoCards/fetchSingleOfType" }),
    setType(type) {
      this.type = type;
    },
    handleModalHidden() {
      this.type = undefined;
      this.infoCard = undefined;
      this.$refs.dragablemodal.hide();
    },
    async handleSubmit() {
      this.submitting = true;
      try {
        const form = this.$refs.infoCardForm;
        await form.submit();
        this.handleModalHidden();
        return this.resolvePromise({ id: this.resourceId });
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
    async openWithData(
      location = undefined,
      name = undefined,
      supplierContactId = undefined
    ) {
      this.resourceId = uuid.v4();
      this.location = location;
      this.name = name;
      this.supplierContactId = supplierContactId;
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async open(location = undefined) {
      this.resourceId = uuid.v4();
      this.location = location;
      this.$refs.dragablemodal.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card-type-picker {
  display: flex;
  justify-content: space-evenly;
  .type-option {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 17px;
      margin-top: 10px;
    }
  }
}
</style>