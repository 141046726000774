<template>
  <dragablemodal
    :name="'confirmModal' + name"
    :ref="name"
    :height="height"
    :topClass="topClass"
    :width="500"
  >
    <header class="modal-header">
      <h4 class="modal-title"></h4>
    </header>
    <div class="modals-content">
      <b-row>
        <b-col cols="2" class="d-flex justify-content-center">
          <feather-icon icon="AlertTriangleIcon" size="70" />
        </b-col>
        <b-col cols="8" class="d-flex align-items-center">
          <h3 class="msg">{{ text }}</h3>
        </b-col>
      </b-row>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="handleAccept">
          {{ T("Web.Generic.Okay", "Okay") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleReject"
        >
          {{ T("Web.Generic.Cancel", "Cancel") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from "bootstrap-vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";

export default {
  components: {
    BRow, 
    BCol,
    BModal,
    BButton,
    dragablemodal,
  },
  props: ["refname", "topClass"],
  created() {
    if (this.refname != undefined) this.name = this.refname;
  },
  data() {
    return {
      name: "dragablemodal",
      resolvePromise: undefined,
      rejectPromise: undefined,
      text: "",
      height: "auto",
    };
  },
  methods: {
    confirm(msg = this.T("Web.Generic.DefaultComfirmModalText", "Are you sure you want to continue?")) {
      this.$refs[this.name].show();
      this.text = msg;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.$refs[this.name].hide();
    },
    handleAccept() {
      this.resolvePromise(true);
      this.handleModalHidden();
    },
    handleReject() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
};
</script>
<style lang="scss" scoped>
.msg {
  text-align: center;
}
</style>