<template>
  <div>
    <div class="month-row" @click="expanded = !expanded">
      <span class="month-label">{{ month.label }}</span>
      <div class="month-entries">
        <div class="d-flex">
          <span></span>
          <span>{{ totalQty | toCurrency }}</span>
          <span><!--{{ totalUnitPrice | toCurrency }}--></span>
          <span>{{ totalPrice | toCurrency }}</span>
          <span>
            <feather-icon
              v-if="entries.length > 0"
              :icon="expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
          /></span>
        </div>
      </div>
    </div>
    <div v-if="expanded">
      <month-posting-list
        :category="category"
        :month="month"
        :entries="entries"
      />
    </div>
  </div>
</template>

<script>
import MonthPostingList from "./MonthPostingList.vue";
export default {
  components: { MonthPostingList },
  props: {
    month: {
      required: true,
    },
    entries: {
      required: true,
    },
    category: {
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    totalWasteCollections() {
      return this.entries.reduce((curr, prev) => (curr += prev.quantity), 0);
    },
    totalUnitPrice() {
      return this.entries.reduce((curr, prev) => (curr += prev.unitPrice), 0);
    },
    totalPrice() {
      return this.entries.reduce((curr, prev) => (curr += prev.totalPrice), 0);
    },
    totalEconnomy() {
      return this.entries.reduce((curr, prev) => (curr += prev.economic), 0);
    },
    totalTonsCollected() {
      return this.entries.reduce(
        (curr, prev) => (curr += prev.tonsWasteCollected),
        0
      );
    },
    totalQty() {
      return this.entries.reduce((curr, prev) => (curr += prev.quantity), 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.month-row {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 4px 0px;
  span.month-label {
    background: #f4f4fb;
    border-radius: 4px;
    padding: 10px 0px;
    width: 100px;
    margin-right: 5px;
    color: #6e6b7b;
    font-weight: bold;
    text-align: center;
  }
  div.month-entries {
    width: 100%;
    font-weight: normal;
    background: #f4f4fb;
    border-radius: 4px;
    padding: 10px 0px;
    width: 100%;

    div:first-child {
      width: calc(100% + 110px);
      padding: 0px 10px;
      margin-left: -110px;
      span {
        text-align: center;
        width: 24%;
        &:last-child {
          width: 4%;
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
    div.month-entries,
    span.month-label {
      background: #d7d7df;
    }
  }
}
</style>